import React from "react";
import Axios, { CancelToken } from "axios";
import {
  EMensagemDestinatario,
  EUserType,
  IAluno,
  ICliente,
  IFiltroMateria,
  IMensagem,
  IMensagemArquivo,
  IMensagemAtribuicao,
  IMensagemAtribuicaoAluno,
  IMensagemAtribuicaoLoginExtra,
  IMensagemAtribuicaoProfessor,
  IMensagemAtribuicaoResponsavel,
  IMensagemLink,
  IMensagemPerguntaFormatada,
  IFiltroSerie,
  IFiltroTurma,
  IUser,
  yupMensagemAtribuicao,
  IFiltroCurso,
} from "@deltasge/marauders-map";

import { store } from "store";
import { api } from "configs";
import { snack } from "components/GlobalSnackbar";
import { getError } from "utils";
import { sendS3File } from "utils/process-files-s3";
import { format, set } from "date-fns";
import { IFilterMensagem } from "interfaces";

export interface IGenericRecipient {
  value: number;
  name: string;
  details?: string | undefined;
}

export interface IPreMensagemArquivo extends IMensagemArquivo {
  file?: File;
}

const getSubtitle = ({
  curso,
  serie,
  turma,
  materia,
}: {
  curso?: IFiltroCurso;
  serie?: IFiltroSerie;
  turma?: IFiltroTurma;
  materia?: IFiltroMateria;
}): string => {
  const title = "Publicações";
  if (turma)
    return title
      .concat(` da turma ${turma.tituloTurma}`)
      .concat(` - ${materia?.nome ?? "Todas as matérias"}`);
  if (serie)
    return title
      .concat(` da série ${serie.descricao}`)
      .concat(` - ${materia?.nome ?? "Todas as matérias"}`);
  if (curso)
    return title
      .concat(` do curso ${curso.nome}`)
      .concat(` - ${materia?.nome ?? "Todas as matérias"}`);
  return title
    .concat(" de todos os cursos")
    .concat(` - ${materia?.nome ?? "Todas as matérias"}`);
};

const getDestinatario = ({
  atribuicoes,
}: {
  atribuicoes?: IMensagemAtribuicao[];
}): EMensagemDestinatario[] => {
  if (!atribuicoes || !atribuicoes[0]) return [];

  const result: EMensagemDestinatario[] = [];

  if (atribuicoes[0].aluno) result.push(EMensagemDestinatario.Alunos);
  if (atribuicoes[0].responsavel)
    result.push(EMensagemDestinatario.Responsáveis);
  if (atribuicoes[0].loginExtra)
    result.push(EMensagemDestinatario["Logins extra do Aluno"]);
  if (atribuicoes[0].professor) result.push(EMensagemDestinatario.Professores);

  return result;
};

const getDestinatarios = async ({
  destinatario,
  turmas,
  cancelToken,
}: {
  destinatario: EMensagemDestinatario;
  turmas: IFiltroTurma[];
  cancelToken: CancelToken;
}): Promise<IGenericRecipient[]> => {
  let url = "";
  let params = {};
  if (destinatario == EMensagemDestinatario.Alunos) {
    url = "aluno/matriculados";
    params = {
      ["matriculas.idTurma.in"]: turmas.map((m) => m.id).join(","),
      select: ["nome", "uid", "nrChamada"],
      order: {
        nrChamada: "ASC",
      },
    };
  } else if (destinatario == EMensagemDestinatario.Responsáveis) {
    url = "aluno/matriculados";
    params = {
      ["matriculas.idTurma.in"]: turmas.map((m) => m.id).join(","),
      select: ["nome", "uid", "nrChamada", "cliente.nome", "cliente.id"],
      order: {
        nrChamada: "ASC",
      },
    };
  } else if (destinatario == EMensagemDestinatario["Logins extra do Aluno"]) {
    url = "aluno/matriculados";
    params = {
      ["matriculas.idTurma.in"]: turmas.map((m) => m.id).join(","),
      select: [
        "nome",
        "uid",
        "nrChamada",
        "alunoLogins.tipo",
        "alunoLogins.id",
      ],
      order: {
        nrChamada: "ASC",
      },
    };
  }
  if (destinatario == EMensagemDestinatario.Professores) {
    const idProfessores: number[] = [];
    for (const turma of turmas) {
      idProfessores.push(
        ...((turma.materias ?? [])
          .map((m) => m.idProfessor)
          .filter((f) => f != undefined) as number[])
      );
    }
    const {
      treeView: { professores },
    } = store.getState();
    return professores
      .filter((f) => f.id != undefined && idProfessores.includes(f.id ?? 0))
      .map<IGenericRecipient>((m) => ({
        name: m.nome ?? "",
        value: m.id ?? 0,
        details: `Código: ${m.codigo}`,
      }));
  }

  const { data } = await api.get<[object[]]>(url, {
    cancelToken,
    params,
  });
  let result: IGenericRecipient[] = [];
  if (destinatario == EMensagemDestinatario.Alunos) {
    result = (data[0] as (IAluno & { nrChamada?: string })[]).map((m) => ({
      name: `${m.nrChamada} - ${m.nome}`,
      value: m.uid || 0,
    }));
  } else if (destinatario == EMensagemDestinatario.Responsáveis) {
    result = (
      data[0] as (IAluno & {
        nrChamada?: string;
        cliente?: ICliente;
      })[]
    ).map((m) => ({
      name: m.cliente?.nome ?? "",
      value: m.cliente?.id ?? 0,
      details: `${m.nrChamada} - ${m.nome}`,
    }));
  } else if (destinatario == EMensagemDestinatario["Logins extra do Aluno"]) {
    const loginsExtra = (
      data[0] as (IAluno & {
        nrChamada?: string;
      })[]
    ).filter((f) => f.alunoLogins && f.alunoLogins.length > 0);
    if (loginsExtra.length > 0) {
      result = loginsExtra.map<IGenericRecipient>((m) => ({
        name: m.alunoLogins ? m.alunoLogins[0].tipo : "",
        value: m.alunoLogins ? m.alunoLogins[0].id ?? 0 : 0,
        details: `${m.nrChamada} - ${m.nome}`,
      }));
    }
  }
  return result;
};

const getMensagem = async ({
  idMensagem,
  cancelToken,
  para = "cadastro",
}: {
  idMensagem: number;
  cancelToken: CancelToken;
  para?: "cadastro" | "detalhes";
}): Promise<IMensagem | undefined> => {
  try {
    let select = [
      "mensagemAtribuicoes.aluno",
      "mensagemAtribuicoes.loginExtra",
      "mensagemAtribuicoes.professor",
      "mensagemAtribuicoes.responsavel",
      "mensagemAtribuicoes.idCurso",
      "mensagemAtribuicoes.idSerie",
      "mensagemAtribuicoes.idTurma",
      "mensagemAtribuicoes.idMateria",
      "mensagemAtribuicoes.id",
      "mensagemAtribuicoes.idMensagem",
      "mensagemAtribuicoes.anoLetivo",
      "mensagemAtribuicoes.mensagemAtribuicaoResponsaveis",
      "mensagemAtribuicoes.mensagemAtribuicaoLoginsExtras",
      "mensagemAtribuicoes.mensagemAtribuicaoProfessores",
      "mensagemAtribuicoes.mensagemAtribuicaoAlunos",
      "mensagemArquivos.arquivo.caminhoArquivo",
      "mensagemArquivos.arquivo.hash",
      "mensagemArquivos.arquivo.nomeArquivo",
      "mensagemArquivos.idArquivo",
      "mensagemArquivos.idMensagem",
      "mensagemArquivos.ordem",
      "mensagemLinks.id",
      "mensagemLinks.idMensagem",
      "mensagemLinks.ordem",
      "mensagemLinks.url",
      "perguntasFormatadas.id",
      "perguntasFormatadas.idMensagem",
      "perguntasFormatadas.nome",
    ];

    if (para == "detalhes") {
      select = [
        "id",
        "createdAt",
        "updatedAt",
        "enviarEm",
        "permiteResposta",
        "respostaComAnexo",
        "programarEnvio",
        "respostaAte",
        "notificaResposta",
        "texto",
        "titulo",
        "rascunho",
        "idMensagemCategoria",
        "usuario.nome",
        "professor.nome",
        "mensagemAtribuicoes.aluno",
        "mensagemAtribuicoes.loginExtra",
        "mensagemAtribuicoes.professor",
        "mensagemAtribuicoes.responsavel",
        "mensagemAtribuicoes.curso.nome",
        "mensagemAtribuicoes.serie.descricao",
        "mensagemAtribuicoes.turma.tituloTurma",
        "mensagemAtribuicoes.materia.nome",
        "mensagemAtribuicoes.id",
        "mensagemArquivos.arquivo.caminhoArquivo",
        "mensagemArquivos.arquivo.hash",
        "mensagemArquivos.arquivo.nomeArquivo",
        "mensagemArquivos.ordem",
        "mensagemArquivos.idArquivo",
        "mensagemArquivos.idMensagem",
        "mensagemLinks.ordem",
        "mensagemLinks.url",
        "mensagemLinks.id",
        "perguntasFormatadas.nome",
        "perguntasFormatadas.id",
        "mensagemCategoria.nome",
        "mensagemCurtidas.id",
      ];
    }

    const { data } = await api.get<IMensagem>(
      `educador/mensagem/${idMensagem}`,
      {
        cancelToken: cancelToken,
        params: {
          select,
          order: {
            mensagemArquivos: { ordem: "ASC" },
          },
        },
      }
    );
    return data;
  } catch (err) {
    if (!Axios.isCancel(err)) {
      snack.warning(getError(err));
    }
  }
};

const buildMensagemAtribuicoes = ({
  tiposAtribuicoes,
  turmasSelecionadas,
  anoLetivo,
  materia,
  ids,
  idMensagem,
}: {
  tiposAtribuicoes: EMensagemDestinatario[];
  turmasSelecionadas: IFiltroTurma[];
  anoLetivo: string;
  materia?: IFiltroMateria;
  ids: number[];
  idMensagem: number | null;
}): IMensagemAtribuicao[] => {
  //#region Destinatario
  const aluno = tiposAtribuicoes.includes(EMensagemDestinatario.Alunos);
  const responsavel = tiposAtribuicoes.includes(
    EMensagemDestinatario.Responsáveis
  );
  const loginExtra = tiposAtribuicoes.includes(
    EMensagemDestinatario["Logins extra do Aluno"]
  );
  const professor = tiposAtribuicoes.includes(
    EMensagemDestinatario.Professores
  );
  //#endregion

  const mensagemAtribuicoes: IMensagemAtribuicao[] = [];

  //#region BaseAtribuicao
  const baseAtribuicao: IMensagemAtribuicao = {
    ...yupMensagemAtribuicao.getDefault(),
    anoLetivo,
    aluno,
    responsavel,
    loginExtra,
    professor,
    idCurso: null,
    idSerie: null,
    idTurma: null,
    idMateria: null,
    idMensagem,
    mensagemAtribuicaoAlunos: aluno
      ? ids.map<IMensagemAtribuicaoAluno>((idAluno) => ({
          idAluno,
          idMensagemAtribuicao: null,
        }))
      : [],
    mensagemAtribuicaoResponsaveis: responsavel
      ? ids.map<IMensagemAtribuicaoResponsavel>((idResponsavel) => ({
          idResponsavel,
          idMensagemAtribuicao: null,
        }))
      : [],
    mensagemAtribuicaoLoginsExtras: loginExtra
      ? ids.map<IMensagemAtribuicaoLoginExtra>((idLoginExtra) => ({
          idLoginExtra,
          idMensagemAtribuicao: null,
        }))
      : [],
    mensagemAtribuicaoProfessores: professor
      ? ids.map<IMensagemAtribuicaoProfessor>((idProfessor) => ({
          idProfessor,
          idMensagemAtribuicao: null,
        }))
      : [],
  };
  //#endregion

  mensagemAtribuicoes.push(
    ...turmasSelecionadas.map((turma) => ({
      ...baseAtribuicao,
      idCurso: turma.idCurso ?? null,
      idSerie: turma.idSerie ?? null,
      idTurma: turma.id ?? null,
      idMateria: materia?.id ?? null,
    }))
  );

  return mensagemAtribuicoes;
};

const renderSelectTurmas = (
  selected: number[],
  turmas: IFiltroTurma[]
): React.ReactNode => {
  if (selected.length == turmas.length) return "Todas as turmas";
  else if (selected.length == 1)
    return turmas.find((f) => selected.includes(f.id ?? 0))?.tituloTurma;
  else if (selected.length > 1) return `${selected.length} Turmas`;
  else return "Nenhuma turma selecionada";
};

const handleChangeTipoAtribuicao = (
  mensagemDestinatario: EMensagemDestinatario,
  tiposAtribuicoes: EMensagemDestinatario[]
): EMensagemDestinatario[] => {
  if (tiposAtribuicoes.includes(mensagemDestinatario)) {
    return tiposAtribuicoes.filter((f) => f != mensagemDestinatario);
  } else {
    return tiposAtribuicoes.concat(mensagemDestinatario);
  }
};

const renderSelectDestinatarios = (
  selected: number[],
  destinatarios: IGenericRecipient[],
  turmas: IFiltroTurma[]
): React.ReactNode => {
  if (selected.length == destinatarios.length) return `Todos os destinatários`;
  else if (selected.length == 1)
    return destinatarios.find((f) => f.value == selected[0])?.name;
  else if (destinatarios.length == 0 && turmas.length <= 0)
    return `Nenhum destinatário`;
  else if (selected.length > 1)
    return `${selected.length} destinatário${selected.length > 1 ? "s" : ""}`;
  return `Todos os destinatários`;
};

const handleChangeTurmas = ({
  value,
  turmas,
  selecionadas,
  set,
}: {
  value: number[];
  turmas: IFiltroTurma[];
  selecionadas: IFiltroTurma[];
  set: (turmas: IFiltroTurma[]) => void;
}): void => {
  const hasTodasTurmas = (value as number[]).includes(-1);
  if (hasTodasTurmas && turmas.length > selecionadas.length) {
    set(turmas);
  } else if (hasTodasTurmas && turmas.length <= selecionadas.length) {
    set([]);
  } else {
    set(turmas.filter((f) => (value as number[]).includes(f.id ?? 0)));
  }
};

const handlePerguntaFormatada = ({
  pergunta,
  perguntasFormatadas,
  setValue,
  hideDialog,
  hasDelete = false,
  oldPergunta,
}: {
  pergunta: IMensagemPerguntaFormatada;
  perguntasFormatadas?: IMensagemPerguntaFormatada[];
  setValue: (key: keyof IMensagem, value: unknown) => void;
  hideDialog?: () => void;
  hasDelete?: boolean;
  oldPergunta?: IMensagemPerguntaFormatada;
}): void => {
  if (hasDelete) {
    setValue(
      "perguntasFormatadas",
      perguntasFormatadas?.map((m) => ({
        ...m,
        deletedAt: m.nome == pergunta.nome ? new Date() : m.deletedAt,
      }))
    );
  } else {
    if (!perguntasFormatadas) {
      setValue("perguntasFormatadas", [pergunta]);
    } else {
      const index = perguntasFormatadas.findIndex(
        (f) => f.nome == (oldPergunta ?? pergunta).nome
      );
      if (index >= 0) {
        perguntasFormatadas[index] = pergunta;
      } else {
        perguntasFormatadas.push(pergunta);
      }
      setValue("perguntasFormatadas", perguntasFormatadas);
    }
    if (hideDialog) hideDialog();
  }
};

const handleLink = ({
  link,
  links,
  setValue,
  hideDialog,
  hasDelete = false,
  oldLink,
}: {
  link: IMensagemLink;
  links?: IMensagemLink[];
  setValue: (key: keyof IMensagem, value: unknown) => void;
  hideDialog?: () => void;
  hasDelete?: boolean;
  oldLink?: IMensagemLink;
}): void => {
  if (hasDelete) {
    setValue(
      "mensagemLinks",
      links?.map((m) => ({
        ...m,
        deletedAt: m.url == link.url ? new Date() : m.deletedAt,
      }))
    );
  } else {
    if (!links) {
      setValue("mensagemLinks", [link]);
    } else {
      const index = links.findIndex((f) => f.url == (oldLink ?? link).url);
      if (index >= 0) {
        links[index] = link;
      } else {
        links.push(link);
      }
      setValue("mensagemLinks", links);
    }
    if (hideDialog) hideDialog();
  }
};

const handleSubmitMensagem = async ({
  payload,
  cancelToken,
  handleClose,
  usuario,
}: {
  payload: IMensagem;
  cancelToken: CancelToken;
  handleClose: () => void;
  usuario: IUser;
}) => {
  try {
    const mensagemArquivos = await Promise.all(
      (payload.mensagemArquivos ?? []).map(
        async (item): Promise<IMensagemArquivo> => {
          const { file } = item as IPreMensagemArquivo;
          if (file) {
            const arquivo = await sendS3File({
              file,
            });
            item.idArquivo = arquivo.id ?? "";
            item.idMensagem = payload.id ?? null;
          }
          return item;
        }
      )
    );
    payload.mensagemArquivos = mensagemArquivos;

    payload.idUsuario = null;
    payload.idProfessor = null;
    if (usuario.tipoUsuario == EUserType.Professor) {
      payload.idProfessor = usuario.id ?? null;
    } else {
      payload.idUsuario = usuario.id ?? null;
    }

    if (payload.id) {
      await api.put(`educador/mensagem/${payload.id}`, payload, {
        cancelToken,
        params: {
          sendNotification: 1,
        },
      });
    } else {
      await api.post("educador/mensagem", payload, {
        cancelToken,
        params: {
          sendNotification: 1,
        },
      });
    }
    handleClose();
  } catch (err) {
    if (!Axios.isCancel(err)) {
      snack.warning(getError(err));
    }
  }
};

const getMateriasBySeries = (turmas: IFiltroTurma[]): IFiltroMateria[] => {
  const materias: IFiltroMateria[] = [];

  const groupedSeries = turmas.reduce(
    (entryMap, e) =>
      entryMap.set(e.idSerie, [...(entryMap.get(e.idSerie) || []), e]),
    new Map()
  );

  if (groupedSeries.size == 1) {
    const turmas = groupedSeries.values().next().value as IFiltroTurma[];

    const mesmasMaterias = turmas
      .map((turma, index) => {
        const curr = turma.materias;
        const next = turmas.at(index + 1)?.materias;
        const prev = turmas.at(index - 1)?.materias;
        let isEqual = false;
        if (prev && curr) {
          isEqual =
            JSON.stringify(curr.map((m) => m.id).sort()) ===
            JSON.stringify(prev.map((m) => m.id).sort());
        }
        if (next && curr) {
          isEqual =
            JSON.stringify(curr.map((m) => m.id).sort()) ===
            JSON.stringify(next.map((m) => m.id).sort());
        }
        return isEqual;
      })
      .every((e) => e == true);
    if (mesmasMaterias && turmas[0].materias && turmas[0].materias.length > 0) {
      materias.push(...turmas[0].materias);
    }
  }

  return materias;
};

const showTabDetalhesMensagem = ({
  atr,
  tipo,
}: {
  atr?: IMensagemAtribuicao[];
  tipo: "aluno" | "responsavel" | "professor" | "loginExtra";
}): boolean => {
  if (!atr) return false;
  return (
    (tipo == "aluno" && atr[0].aluno) ||
    (tipo == "responsavel" && atr[0].responsavel) ||
    (tipo == "loginExtra" && atr[0].loginExtra) ||
    (tipo == "professor" && atr[0].professor)
  );
};

const getWhereMensagensRecebidas = ({
  idCategoria,
  filter,
}: {
  filter: IFilterMensagem;
  idCategoria?: string;
}) => {
  let dataIni: string | undefined = undefined;
  let dataFim: string | undefined = undefined;
  if (filter.dataIni && filter.dataFim) {
    dataIni = format(filter.dataIni, "yyyy-MM-dd HH:mm:ss");
    dataFim = format(
      set(filter.dataFim, { hours: 23, minutes: 59 }),
      "yyyy-MM-dd HH:mm:ss"
    );
  }
  let turma:
    | {
        idCurso?: number;
        idSerie?: number;
        idTurma?: number;
      }
    | undefined;
  if (filter.idCurso || filter.idSerie || filter.idTurma) {
    turma = {
      idCurso: filter.idCurso,
      idSerie: filter.idSerie,
      idTurma: filter.idTurma,
    };
  }
  return {
    idCategoria,
    ["texto.like"]: filter.texto,
    dataIni,
    dataFim,
    turma,
  };
};

const buildDestinatariosSelecionados = ({
  mensagemAtribuicao,
}: {
  mensagemAtribuicao: IMensagemAtribuicao;
}): number[] => {
  let ids: number[] = [];
  if (mensagemAtribuicao.aluno && mensagemAtribuicao.mensagemAtribuicaoAlunos) {
    ids = mensagemAtribuicao.mensagemAtribuicaoAlunos.map((m) => m.idAluno);
  } else if (
    mensagemAtribuicao.responsavel &&
    mensagemAtribuicao.mensagemAtribuicaoResponsaveis
  ) {
    ids = mensagemAtribuicao.mensagemAtribuicaoResponsaveis.map(
      (m) => m.idResponsavel
    );
  } else if (
    mensagemAtribuicao.loginExtra &&
    mensagemAtribuicao.mensagemAtribuicaoLoginsExtras
  ) {
    ids = mensagemAtribuicao.mensagemAtribuicaoLoginsExtras.map(
      (m) => m.idLoginExtra
    );
  } else if (
    mensagemAtribuicao.professor &&
    mensagemAtribuicao.mensagemAtribuicaoProfessores
  ) {
    ids = mensagemAtribuicao.mensagemAtribuicaoProfessores.map(
      (m) => m.idProfessor
    );
  }
  return ids;
};

const camposPublicacao = (raiz?: string) =>
  [
    "copiaEmail",
    "enviarEm",
    "excluido",
    "exportado",
    "id",
    "idMensagemCategoria",
    "idProfessor",
    "idUsuario",
    "permiteResposta",
    "notificaResposta",
    "programarEnvio",
    "respostaAte",
    "respostaComAnexo",
    "texto",
    "mensagemAtribuicoes",
    "mensagemLinks",
    "mensagemArquivos.ordem",
    "mensagemArquivos.idArquivo",
    "mensagemArquivos.idMensagem",
    "mensagemArquivos.arquivo.caminhoArquivo",
    "mensagemArquivos.arquivo.hash",
    "mensagemArquivos.arquivo.nomeArquivo",
    "perguntasFormatadas",
    "perguntasFormatadas.id",
    "perguntasFormatadas.nome",
  ].map((campo) => `${raiz ? raiz + "." : ""}${campo}`);

export const utilMensagem = {
  getDestinatarios,
  getDestinatario,
  getMensagem,
  getSubtitle,
  buildMensagemAtribuicoes,
  renderSelectTurmas,
  renderSelectDestinatarios,
  handleChangeTurmas,
  handlePerguntaFormatada,
  handleLink,
  handleSubmitMensagem,
  handleChangeTipoAtribuicao,
  getMateriasBySeries,
  showTabDetalhesMensagem,
  getWhereMensagensRecebidas,
  buildDestinatariosSelecionados,
  camposPublicacao,
};
