import React, { FC, Fragment } from "react";
import { IMensagem } from "@deltasge/marauders-map";
import sanitizeHtml from "sanitize-html";
import { decode } from "html-entities";
import {
  IconButton,
  Tooltip,
  Typography,
  TableRow,
  TableCell,
  Box,
} from "@mui/material";
import {
  TextBoxSearch as DetailIcon,
  AlertDecagram as IsNewIcon,
  Check as CheckIcon,
} from "mdi-material-ui";
import {
  differenceInMinutes,
  format,
  formatDistance,
  parseISO,
} from "date-fns";
import { ptBR } from "date-fns/locale";

export const RowRecebida: FC<{
  item: IMensagem;
  handleDetalhes: (idMensagem?: number) => void;
}> = ({ item, handleDetalhes }) => {
  const atribuicao =
    item.mensagemAtribuicoes != null ? item.mensagemAtribuicoes[0] : null;
  const isNew =
    !item.mensagemVisualizacoes || item.mensagemVisualizacoes.length == 0;
  let enviadoPor = "";
  let turma = "";
  if (atribuicao?.turma) {
    turma = atribuicao?.turma?.tituloTurma;
  } else if (atribuicao?.serie) {
    turma = `Todas as turmas do(a) ${atribuicao?.serie?.descricao}`;
  } else if (atribuicao?.curso) {
    turma = ` Todas as séries do ${atribuicao?.curso?.nome}`;
  } else {
    turma = "Todos os cursos";
  }
  if (item.usuario) {
    enviadoPor = item.usuario.nome;
  } else if (item?.professor) {
    enviadoPor = item.professor.nome;
  } else {
    enviadoPor = "Não identificado";
  }

  let respostaAte: Date | undefined = undefined;
  if (item.respostaAte) {
    respostaAte = parseISO(item.respostaAte.toString());
  }

  let title = item.titulo;
  if (item.texto && !item.titulo) {
    title = decode(
      sanitizeHtml(item.texto, {
        allowedTags: [],
      })
    );
    if (title.length > 200) {
      title = title.substring(0, 100).concat("...");
    }
  }

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset !important" } }}>
        <TableCell>
          <Box sx={{ display: "flex" }}>
            {isNew ? (
              <IsNewIcon fontSize="small" color="secondary" sx={{ mr: 1 }} />
            ) : (
              <CheckIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
            )}
            <Typography variant="caption" sx={{ flex: 1 }}>
              {item.mensagemCategoria?.nome}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Tooltip
            title={format(
              parseISO((item.createdAt ?? "") as string),
              "dd/MM/yyyy H:mm:ss"
            )}
          >
            <Typography variant="caption">
              {formatDistance(
                parseISO((item.createdAt ?? "") as string),
                new Date(),
                { locale: ptBR, addSuffix: true }
              )}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Typography variant="caption">{turma}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="caption">{enviadoPor}</Typography>
        </TableCell>
        <TableCell>
          {respostaAte && (
            <Tooltip title={format(respostaAte, "dd/MM/yyyy H:mm:ss")}>
              <Typography
                variant="caption"
                color={
                  differenceInMinutes(respostaAte, Date.now()) > 0
                    ? "primary"
                    : "inherit"
                }
              >
                {formatDistance(respostaAte, new Date(), {
                  addSuffix: true,
                  locale: ptBR,
                })}
              </Typography>
            </Tooltip>
          )}
          {!respostaAte && (
            <Typography variant="caption">Não determinado</Typography>
          )}
        </TableCell>
        <TableCell sx={{ textAlign: "right" }}>
          <Tooltip title="Detalhes">
            <IconButton onClick={() => handleDetalhes(item.id)}>
              <DetailIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Typography variant="caption">{title}</Typography>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
