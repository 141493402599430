import React, { FC, Fragment, useState } from "react";
import { IMensagem } from "@deltasge/marauders-map";
import sanitizeHtml from "sanitize-html";
import { decode } from "html-entities";

import {
  DotsVertical as MoreIcon,
  Pencil as EditIcon,
  Delete as DeleteIcon,
  TextBoxSearch as DetailIcon,
  Check as PublicarIcon,
  Heart as CurtidaIcon,
} from "mdi-material-ui";

import {
  differenceInMinutes,
  format,
  formatDistance,
  parseISO,
} from "date-fns";
import { ptBR } from "date-fns/locale";
import { useMobile } from "hooks/useMobile";
import {
  Badge,
  Chip,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

export const Row: FC<{
  item: IMensagem;
  handleEdit: (idMensagem?: number) => void;
  handleDetalhes: (idMensagem?: number) => void;
  handleDelete: (idMensagem: number) => void;
  handlePublicar: (idMensagem: number, publicar: boolean) => void;
}> = ({ item, handleEdit, handleDetalhes, handleDelete, handlePublicar }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isSm = useMobile("sm");
  const isMd = useMobile("md");
  const isLg = useMobile("lg");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const atribuicao =
    item.mensagemAtribuicoes != null ? item.mensagemAtribuicoes[0] : null;
  const enviadoPara: string[] = [];
  let materia = "Independente da matéria";
  let turma = "Todos os cursos";
  if (atribuicao?.materia) {
    materia = atribuicao.materia?.nome;
  }

  const mensagemAtribuicoes = item.mensagemAtribuicoes ?? [];
  let turmas = mensagemAtribuicoes.map((m) => m.turma?.tituloTurma);

  if (mensagemAtribuicoes.filter((f) => !!f.turma?.tituloTurma).length > 0) {
    turma = mensagemAtribuicoes.map((m) => m.turma?.tituloTurma).join(", ");
  }
  if (turmas.length > 3) {
    turma = turmas.slice(0, 3).join(", ");
    turmas = turmas.slice(3);
  }

  if (atribuicao?.aluno) {
    enviadoPara.push("Alunos");
  }
  if (atribuicao?.responsavel) {
    enviadoPara.push("Responsáveis");
  }
  if (atribuicao?.loginExtra) {
    enviadoPara.push("Logins dos Alunos");
  }
  if (atribuicao?.professor) {
    enviadoPara.push("Professores");
  }

  let title = item.titulo;
  if (item.texto && !item.titulo) {
    title = decode(
      sanitizeHtml(item.texto, {
        allowedTags: [],
      })
    );
    if (title.length > 200) {
      title = title.substring(0, 100).concat("...");
    }
  }

  const qtdeCurtidas = (item.mensagemCurtidas ?? []).length;
  let curtidas = "Nenhuma curtida";
  if (qtdeCurtidas == 1) curtidas = "Uma curtida";
  else if (qtdeCurtidas > 1) curtidas = `${qtdeCurtidas} curtidas`;

  // TODO: Criar progresso de entrega
  let progress: number | undefined = undefined;
  if (
    item.permiteResposta &&
    ((item.perguntasFormatadas ?? []).length > 0 || item.respostaComAnexo)
  ) {
    progress = undefined; // Math.floor(Math.random() * (100 - 0 + 1) + 0);
  }

  return (
    <Fragment>
      <TableRow
        sx={{ "& > td:not([rowSpan])": { borderBottom: "unset !important" } }}
      >
        {!isMd && (
          <TableCell>
            <Typography variant="caption">
              {item.mensagemCategoria?.nome}
            </Typography>
          </TableCell>
        )}
        <TableCell>
          <Tooltip
            title={format(
              parseISO((item.createdAt ?? "") as string),
              "dd/MM/yyyy H:mm:ss"
            )}
          >
            <Typography variant="caption">
              {formatDistance(
                parseISO((item.createdAt ?? "") as string),
                new Date(),
                { locale: ptBR, addSuffix: true }
              )}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Typography variant="caption">
            {turma}
            &nbsp;
          </Typography>
          {turmas.length > 3 && (
            <Tooltip title={turmas.join(", ")}>
              <Chip label={`+ ${turmas.length}`} size="small" color="primary" />
            </Tooltip>
          )}
        </TableCell>
        <TableCell>
          <Typography variant="caption">{materia.rUpper()}</Typography>
        </TableCell>
        {!isLg && (
          <TableCell>
            <Typography variant="caption">{enviadoPara.join(", ")}</Typography>
          </TableCell>
        )}
        <TableCell rowSpan={2}>
          {item.rascunho && (
            <Typography variant="caption" component="p">
              Rascunho
            </Typography>
          )}
          {!item.rascunho &&
            item.enviarEm &&
            differenceInMinutes(
              parseISO(item.enviarEm.toString()),
              new Date()
            ) > 0 && (
              <Tooltip
                title={format(
                  parseISO((item.enviarEm.toString() ?? "") as string),
                  "dd/MM/yyyy"
                )}
              >
                <Typography variant="caption" component="p">
                  {formatDistance(
                    parseISO(item.enviarEm.toString()),
                    new Date(),
                    {
                      addSuffix: true,
                      locale: ptBR,
                    }
                  )}
                </Typography>
              </Tooltip>
            )}
          {!item.rascunho &&
            (!item.enviarEm ||
              differenceInMinutes(
                parseISO(item.enviarEm.toString()),
                new Date()
              ) <= 0) && (
              <Typography variant="caption" component="p">
                Sim
              </Typography>
            )}
          {progress && (
            <LinearProgress variant="determinate" value={progress} />
          )}
        </TableCell>
        <TableCell sx={{ textAlign: "right" }} rowSpan={2}>
          {!isSm && (
            <>
              <Tooltip title={curtidas}>
                <span>
                  <IconButton
                    disabled={qtdeCurtidas == 0}
                    color={"error"}
                    disableRipple
                    sx={{ cursor: "default" }}
                  >
                    <Badge badgeContent={qtdeCurtidas}>
                      <CurtidaIcon />
                    </Badge>
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={!item.rascunho ? "Publicado" : "Publicar"}>
                <IconButton
                  size="small"
                  color={!item.rascunho ? "primary" : "secondary"}
                  onClick={() => handlePublicar(item.id ?? 0, !item.rascunho)}
                >
                  <PublicarIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Detalhes">
                <IconButton
                  size="small"
                  onClick={() => handleDetalhes(item.id)}
                >
                  <DetailIcon />
                </IconButton>
              </Tooltip>
              <IconButton onClick={handleClick} size="small">
                <MoreIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleEdit(item.id);
                  }}
                >
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Editar</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleDelete(item.id ?? 0);
                  }}
                >
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Excluir</ListItemText>
                </MenuItem>
              </Menu>
            </>
          )}
          {isSm && (
            <>
              <IconButton onClick={handleClick}>
                <MoreIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handlePublicar(item.id ?? 0, !item.rascunho);
                  }}
                >
                  <ListItemIcon>
                    <PublicarIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    {!item.rascunho ? "Publicado" : "Publicar"}
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleDetalhes(item.id);
                  }}
                >
                  <ListItemIcon>
                    <DetailIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Detalhes</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleEdit(item.id);
                  }}
                >
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Editar</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleDelete(item.id ?? 0);
                  }}
                >
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Excluir</ListItemText>
                </MenuItem>
              </Menu>
            </>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={!isMd ? 5 : 4}
        >
          <Typography variant="caption">{title}</Typography>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
