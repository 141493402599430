import { SxProps, Theme } from "@mui/material";

export const paper: SxProps<Theme> = {
  marginTop: (theme) => theme.spacing(8),
  padding: (theme) => theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export const title: SxProps<Theme> = {
  textAlign: "center",
  marginBottom: (theme) => theme.spacing(1),
};
