import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import Axios, { CancelTokenSource } from "axios";
import {
  EMensagemDestinatario,
  IMensagem,
  IMensagemAtribuicao,
  IMensagemAtribuicaoDestinatario,
  IMensagemAtribuicaoDetalhe,
} from "@deltasge/marauders-map";

import { Alert as AlertMUI, Grid, LinearProgress } from "@mui/material";

import { Alert } from "components/Alert";
import { snack } from "components/GlobalSnackbar";
import { Hidden } from "components/Hidden";
import { api } from "configs";
import { useMobile } from "hooks/useMobile";
import { useWidth } from "hooks/useWidth";
import { getError } from "utils";

import { DetalhesDestinatario } from "./DetalhesDestinatario";
import { ListDestinatario } from "./ListDestinatario";
import { IFilter } from "../Detalhes";

import imgPergunta from "assets/images/task-check.png";
import imgAnexo from "assets/images/attachment.png";
import imgChat from "assets/images/chat.png";

export const TabContent: FC<{
  mensagemAtribuicoes: IMensagemAtribuicao[];
  mensagem: IMensagem;
  source: CancelTokenSource;
  atribuicoes: IMensagemAtribuicaoDetalhe[];
  setAtribuicoes: Dispatch<SetStateAction<IMensagemAtribuicaoDetalhe[]>>;
  setDestinatarioEscolhidos: Dispatch<
    SetStateAction<IMensagemAtribuicaoDestinatario[]>
  >;
  destinatarioEscolhidos: IMensagemAtribuicaoDestinatario[];
  escolherDestinatario: boolean;
  filter: IFilter;
}> = ({
  mensagemAtribuicoes,
  source,
  setAtribuicoes,
  atribuicoes: todos,
  mensagem,
  filter,
  destinatarioEscolhidos,
  escolherDestinatario,
  setDestinatarioEscolhidos,
}) => {
  const [loading, setLoading] = useState(false);
  const { destinatario: destinatarioSelecionado, tab } = useParams<{
    destinatario?: string;
    tab: string;
  }>();
  const isLg = useMobile("lg");
  const width = useWidth();

  let tipoDestinatario: EMensagemDestinatario | undefined;
  if (tab == "2") {
    tipoDestinatario = EMensagemDestinatario.Alunos;
  } else if (tab == "3") {
    tipoDestinatario = EMensagemDestinatario.Responsáveis;
  } else if (tab == "4") {
    tipoDestinatario = EMensagemDestinatario["Logins extra do Aluno"];
  } else {
    tipoDestinatario = EMensagemDestinatario.Professores;
  }

  useEffect(() => {
    const getDestinatario = async () => {
      try {
        setLoading(true);
        const { data } = await api.post<IMensagemAtribuicaoDetalhe[]>(
          `educador/mensagem/destinatarios`,
          {
            idMensagemAtribuicoes: mensagemAtribuicoes.map((m) => m.id),
            idMensagem: mensagem.id,
            tipoDestinatario,
          },
          {
            cancelToken: source.token,
          }
        );

        setAtribuicoes(data);
      } catch (err) {
        if (!Axios.isCancel(err)) {
          snack.warning(getError(err));
        }
      } finally {
        setLoading(false);
      }
    };
    getDestinatario();
    return () => source.cancel();
  }, [mensagemAtribuicoes]);

  const filtrados = todos.map((atr) => {
    let destinatarios = atr.destinatarios;
    if (filter.pergunta == -1) {
      destinatarios = atr.destinatarios.filter(
        (f) => f.respostaFormatada == undefined
      );
    } else if (filter.pergunta > 0) {
      destinatarios = atr.destinatarios.filter(
        (f) =>
          f.respostaFormatada != undefined &&
          f.respostaFormatada.idMensagemPerguntaFormatada == filter.pergunta
      );
    }

    if (filter.devolvido != undefined) {
      destinatarios = destinatarios.filter(
        (f) =>
          f.resposta != undefined && f.resposta.devolvido == filter.devolvido
      );
    }

    if (filter.visualizacao === true) {
      destinatarios = destinatarios.filter((f) => f.visualizacao != undefined);
    } else if (filter.visualizacao === false) {
      destinatarios = destinatarios.filter((f) => f.visualizacao == undefined);
    }

    if (filter.custida === true) {
      destinatarios = destinatarios.filter((f) => f.curtida != undefined);
    } else if (filter.custida === false) {
      destinatarios = destinatarios.filter((f) => f.curtida == undefined);
    }
    return {
      ...atr,
      destinatarios,
    };
  });

  let destinatario: IMensagemAtribuicaoDestinatario | undefined;
  todos.map((f) => {
    const a = f.destinatarios.find(
      (g) => g.id == parseInt(destinatarioSelecionado ?? "", 10)
    );
    if (a) destinatario = a;
  });

  let divideTela = mensagem.permiteResposta;
  if (
    (mensagem.perguntasFormatadas ?? []).length > 0 &&
    mensagem.permiteResposta
  )
    divideTela = false;

  let img = imgChat;
  if (mensagem.permiteResposta && mensagem.respostaComAnexo) img = imgAnexo;
  else if (
    mensagem.permiteResposta &&
    (mensagem.perguntasFormatadas ?? []).length > 0
  )
    img = imgPergunta;

  return (
    <Grid container spacing={2} height="100%">
      {loading && (
        <Grid item xs={12} p={2}>
          <LinearProgress sx={{ m: 2 }} />
        </Grid>
      )}
      {filtrados.length == 0 && !loading && (
        <Grid item xs={12}>
          <AlertMUI severity="info" sx={{ m: 2 }}>
            Nenhum destinatario encontrado!
          </AlertMUI>
        </Grid>
      )}
      {filtrados.length > 0 && !loading && (
        <>
          <Hidden visible={!destinatario || !isLg}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={divideTela ? 6 : 12}
              height={isLg ? "auto" : "100%"}
            >
              <ListDestinatario
                atribuicoes={filtrados}
                mensagem={mensagem}
                destinatarioEscolhidos={destinatarioEscolhidos}
                escolherDestinatario={escolherDestinatario}
                setDestinatarioEscolhidos={setDestinatarioEscolhidos}
              />
            </Grid>
          </Hidden>
          {divideTela && !destinatario && ["lg", "xl"].includes(width) && (
            <Grid item lg>
              <Alert imageSrc={img} />
            </Grid>
          )}
          {divideTela && !!destinatario && (
            <Grid item xs={12} sm={12} md={12} lg={6} height="100%">
              {destinatario && (
                <DetalhesDestinatario
                  mensagem={mensagem}
                  destinatario={destinatario}
                  source={source}
                  showClose
                />
              )}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
