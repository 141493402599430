import React, { Dispatch, FC, SetStateAction } from "react";
import {
  IMensagem,
  IMensagemAtribuicaoDestinatario,
  IMensagemAtribuicaoDetalhe,
} from "@deltasge/marauders-map";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Alert,
  Badge,
  IconButton,
  List,
  ListItemSecondaryAction,
  ListSubheader,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AccountCheck as EntregueIcon,
  AccountMinus as DevolvidoIcon,
  EyeOutline as VistoIcon,
  Heart as CurtirIcon,
  HeartOutline as SemCurtirIcon,
  Reply as RespFormaIcon,
} from "mdi-material-ui";
import { ListItemDestinatario } from "./ListItemDestinatario";

export const ListDestinatario: FC<{
  atribuicoes: IMensagemAtribuicaoDetalhe[];
  mensagem: IMensagem;
  destinatarioEscolhidos: IMensagemAtribuicaoDestinatario[];
  escolherDestinatario: boolean;
  setDestinatarioEscolhidos: Dispatch<
    SetStateAction<IMensagemAtribuicaoDestinatario[]>
  >;
}> = ({
  atribuicoes,
  mensagem,
  escolherDestinatario,
  destinatarioEscolhidos,
  setDestinatarioEscolhidos,
}) => {
  const hasRespostaFormatada =
    mensagem.permiteResposta && (mensagem.perguntasFormatadas ?? []).length > 0;
  const hasResposta = mensagem.permiteResposta && mensagem.respostaComAnexo;

  return (
    <PerfectScrollbar>
      {atribuicoes.map((atribuicao) => (
        <List
          dense
          sx={{ padding: 1 }}
          key={`atr-${atribuicao.id}-${atribuicao.tipo}`}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" sx={{ p: 0 }}>
              <Typography variant="h6" lineHeight={({ spacing }) => spacing(6)}>
                {atribuicao.descricao}
              </Typography>
              <ListItemSecondaryAction>
                <IconButton
                  disableRipple
                  sx={{ cursor: "default" }}
                  color={atribuicao.curtidas ? "error" : "inherit"}
                >
                  <Tooltip
                    title={
                      atribuicao.curtidas
                        ? `${atribuicao.curtidas} Curtida${
                            atribuicao.curtidas > 1 ? "s" : ""
                          }`
                        : undefined
                    }
                  >
                    <Badge badgeContent={atribuicao.curtidas}>
                      {atribuicao.curtidas ? <CurtirIcon /> : <SemCurtirIcon />}
                    </Badge>
                  </Tooltip>
                </IconButton>
                <IconButton
                  disableRipple
                  sx={{ cursor: "default" }}
                  color={atribuicao.visualizacoes ? "primary" : "inherit"}
                >
                  <Tooltip
                    title={
                      atribuicao.visualizacoes
                        ? `${atribuicao.visualizacoes}${
                            atribuicao.visualizacoes > 1
                              ? " Visualizações"
                              : " Visualização"
                          }`
                        : undefined
                    }
                  >
                    <Badge badgeContent={atribuicao.visualizacoes}>
                      <VistoIcon />
                    </Badge>
                  </Tooltip>
                </IconButton>
                {hasResposta && (
                  <IconButton
                    disableRipple
                    sx={{ cursor: "default" }}
                    color={atribuicao.entregas ? "success" : "inherit"}
                  >
                    <Tooltip
                      title={
                        atribuicao.entregas
                          ? `${atribuicao.entregas} Entrega${
                              atribuicao.entregas > 1 ? "s" : ""
                            }`
                          : undefined
                      }
                    >
                      <Badge badgeContent={atribuicao.entregas}>
                        <EntregueIcon />
                      </Badge>
                    </Tooltip>
                  </IconButton>
                )}
                {hasResposta && (
                  <IconButton
                    disableRipple
                    sx={{ cursor: "default" }}
                    color={atribuicao.devolucoes ? "warning" : "inherit"}
                  >
                    <Tooltip
                      title={
                        atribuicao.devolucoes
                          ? `${atribuicao.devolucoes} ${
                              atribuicao.devolucoes > 1
                                ? "Devoluções"
                                : "Devolução"
                            }`
                          : undefined
                      }
                    >
                      <Badge badgeContent={atribuicao.devolucoes}>
                        <DevolvidoIcon />
                      </Badge>
                    </Tooltip>
                  </IconButton>
                )}
                {hasRespostaFormatada && (
                  <IconButton
                    disableRipple
                    sx={{ cursor: "default" }}
                    color={
                      atribuicao.respostasFormatadas ? "success" : "inherit"
                    }
                  >
                    <Tooltip
                      title={
                        atribuicao.respostasFormatadas
                          ? `${atribuicao.respostasFormatadas} Resposta${
                              atribuicao.respostasFormatadas > 1 ? "s" : ""
                            }`
                          : undefined
                      }
                    >
                      <Badge badgeContent={atribuicao.respostasFormatadas}>
                        <RespFormaIcon />
                      </Badge>
                    </Tooltip>
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListSubheader>
          }
        >
          {atribuicao.destinatarios.length == 0 && (
            <Alert severity="info" sx={{ m: 2 }}>
              Nenhum usuário encontrado!
            </Alert>
          )}
          {atribuicao.destinatarios.length > 0 &&
            atribuicao.destinatarios.map((destinatario, index) => {
              return (
                <ListItemDestinatario
                  key={`destinatario-${index}`}
                  destinatario={destinatario}
                  mensagem={mensagem}
                  destinatarioEscolhidos={destinatarioEscolhidos}
                  escolherDestinatario={escolherDestinatario}
                  setDestinatarioEscolhidos={setDestinatarioEscolhidos}
                />
              );
            })}
        </List>
      ))}
    </PerfectScrollbar>
  );
};
