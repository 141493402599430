import React, { FC } from "react";

import { IMensagem } from "@deltasge/marauders-map";
import { history } from "configs";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Pagination } from "components/Pagination";
import { EmptyState } from "components/EmptyStates";
import { RowRecebida } from "./RowRecebida";

export const TabMensagemRecebida: FC<{
  loading: boolean;
  mensagens: [IMensagem[], number];
  rowsPerPageOptions: number[];
  rowsPerPage: number;
  page: number;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({
  loading,
  mensagens,
  rowsPerPageOptions,
  rowsPerPage,
  page,
  onChangeRowsPerPage,
  onChangePage,
}) => {
  const handleDetalhes = (idMensagem?: number) =>
    history.push(`/publicacoes/recebida/${idMensagem}`);

  if (!loading && mensagens[0].length === 0) {
    return (
      <EmptyState
        type="404"
        title="Nenhuma publicação recebida!"
        subtitle="O Filtro selecionado não encontrou nenhuma publicação"
      />
    );
  }

  return (
    <Paper>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Categoria</TableCell>
              <TableCell>Enviado</TableCell>
              <TableCell>Turma</TableCell>
              <TableCell>Enviado por</TableCell>
              <TableCell>Entrega</TableCell>
              <TableCell sx={{ width: "140px" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mensagens[0].map((item, i) => (
              <RowRecebida
                key={`mensagem-${i}`}
                item={item}
                handleDetalhes={handleDetalhes}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        rowsPerPageOptions={rowsPerPageOptions}
        count={mensagens[1]}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </Paper>
  );
};
