import React, { FC } from "react";
import { NotificationPayload } from "firebase/messaging";
import { EUserType, TPayloadNotification } from "@deltasge/marauders-map";
import { Avatar, Button, Grid, Typography } from "@mui/material";
import { history } from "configs";
import { useSnackbar } from "notistack";

export const NotificationComponent: FC<{
  notification: NotificationPayload;
  payload: TPayloadNotification;
}> = ({ payload, notification }) => {
  const { closeSnackbar } = useSnackbar();

  let image: string | undefined;
  let icon: string | undefined;
  let url: string | undefined;

  let tipoUsuario: EUserType | undefined;
  if (payload.fromId) {
    const deltaIdParts = payload.fromId.split("-");
    if (deltaIdParts.at(1)) tipoUsuario = deltaIdParts.at(1) as EUserType;
  }

  if (payload.image) image = payload.image;
  if (payload.icon) icon = payload.icon;
  if (notification?.image) image = notification?.image;

  if (payload.modulo) {
    let modulo = payload.modulo;
    if (modulo == "mensagens") {
      modulo = "publicacoes/recebida";
      if (
        tipoUsuario &&
        [EUserType.Aluno, EUserType.LoginExtra, EUserType.Responsavel].includes(
          tipoUsuario
        )
      ) {
        modulo = "publicacoes/detalhes";
      }
    }
    url = `${modulo}/${payload.id}`;
  }

  if (url && !url.startsWith("/")) url = `/${url}`;

  return (
    <Grid container alignItems="center">
      {icon && (
        <Grid item sx={{ pr: 1 }}>
          <Avatar src={icon} />
        </Grid>
      )}
      <Grid item flexGrow={1}>
        <Typography variant="subtitle2" component="p">
          {notification?.title}
        </Typography>
        <Typography variant="caption" component="p">
          {notification?.body}
        </Typography>
        {image && <img src={image} />}
      </Grid>
      {url && (
        <Grid item sx={{ pl: 1 }}>
          <Button
            variant="text"
            size="small"
            onClick={() => {
              history.push(url ?? "");
              closeSnackbar();
            }}
          >
            Abrir
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
