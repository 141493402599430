import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import {
  EUserType,
  IMensagem,
  IMensagemResposta,
  IMensagemRespostaFormatada,
  IMensagemVisualizacao,
} from "@deltasge/marauders-map";

import { Grid } from "@mui/material";

import { api } from "configs";
import { useAppSelector } from "store";
import { utilMensagem as util } from "../utilMensagem";
import { Main } from "pages/_layouts/Main";
import {
  ChatMensagem,
  DetalhesMensagem,
  MensagemPerguntaFormatada,
  MensagemResposta,
} from "./components";
import { differenceInMinutes, format, parseISO } from "date-fns";

export const MensagemRecebida: FC = () => {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  const {
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const [mensagem, setMensagem] = useState<IMensagem>();
  const [loading, setLoading] = useState(false);
  const { idMensagem } = useParams<{
    idMensagem: string;
  }>();

  const getMensagem = async (id: number) => {
    setLoading(true);
    const msg = await util.getMensagem({
      idMensagem: id,
      cancelToken: source.token,
      para: "detalhes",
    });
    if (msg) {
      let keyUsuario = "idAluno";
      if (usuario?.tipoUsuario == EUserType.Professor)
        keyUsuario = "idProfessor";
      else if (usuario?.tipoUsuario == EUserType.Responsavel)
        keyUsuario = "idResponsavel";
      else if (usuario?.tipoUsuario == EUserType.LoginExtra)
        keyUsuario = "idLoginExtra";

      //#region Visualização
      const { data: visu } = await api.get<[IMensagemVisualizacao[], number]>(
        "educador/mensagem-visualizacao/",
        {
          cancelToken: source.token,
          params: {
            select: ["id", "createdAt", "updatedAt"],
            [keyUsuario]: usuario?.id ?? 0,
            idMensagem: msg.id ?? 0,
          },
        }
      );
      if (visu && Array.isArray(visu[0]) && visu[0].length > 0) {
        await api.put(`educador/mensagem-visualizacao/${visu[0][0].id}`, {
          [keyUsuario]: usuario?.id ?? 0,
          updatedAt: format(Date.now(), "yyyy-MM-dd HH:mm:ss"),
        });
        msg.mensagemVisualizacoes = visu[0];
      } else {
        const { data } = await api.post<IMensagemVisualizacao>(
          "educador/mensagem-visualizacao/",
          {
            [keyUsuario]: usuario?.id ?? 0,
            idMensagem: msg.id ?? 0,
            idMensagemAtribuicao: (msg.mensagemAtribuicoes ?? [])[0].id ?? 0,
          }
        );
        msg.mensagemVisualizacoes = [data];
      }
      //#endregion

      //#region Respostas formatadas
      if ((msg.perguntasFormatadas ?? []).length > 0) {
        const { data: resForm } = await api.get<
          [IMensagemRespostaFormatada[], number]
        >("educador/mensagem-resposta-formatada", {
          cancelToken: source.token,
          params: {
            select: [
              "id",
              "idMensagemPerguntaFormatada",
              "createdAt",
              "updatedAt",
            ],
            [keyUsuario]: usuario?.id ?? 0,
            idMensagem: msg.id ?? 0,
          },
        });

        if (resForm && Array.isArray(resForm) && resForm[0].length > 0) {
          msg.respostasFormatadas = resForm[0];
        }
      }
      //#endregion

      //#region Respostas com anexos
      if (msg.respostaComAnexo) {
        const { data: respostas } = await api.get<
          [IMensagemResposta[], number]
        >("educador/mensagem-resposta", {
          cancelToken: source.token,
          params: {
            select: [
              "id",
              "createdAt",
              "updatedAt",
              "devolvido",
              "anexos.arquivo.caminhoArquivo",
              "anexos.arquivo.hash",
              "anexos.arquivo.nomeArquivo",
              "anexos.idArquivo",
              "anexos.idMensagem",
              "anexos.ordem",
              "anexos.id",
              "links.id",
              "links.idMensagem",
              "links.ordem",
              "links.url",
            ],
            [keyUsuario]: usuario?.id ?? 0,
            idMensagem: msg.id ?? 0,
          },
        });

        if (respostas && Array.isArray(respostas) && respostas[0].length > 0) {
          msg.respostas = respostas[0];
        }
      }
      //#endregion

      setMensagem(msg);
    }
    //#endregion
    setLoading(false);
  };

  useEffect(() => {
    if (idMensagem && !mensagem) {
      getMensagem(parseInt(idMensagem, 10));
    }
    return () => source.cancel();
  }, []);

  const columnDetails =
    mensagem?.permiteResposta || mensagem?.respostaComAnexo || false;

  return (
    <Main
      title="Publicações"
      subtitle={mensagem?.mensagemCategoria?.nome}
      loading={loading}
    >
      <Grid
        container
        spacing={2}
        sx={{ height: ({ spacing }) => `calc(100% + ${spacing(2)})` }}
      >
        <Grid item sm={12} md={columnDetails ? 8 : 12}>
          {mensagem && <DetalhesMensagem mensagem={mensagem} />}
        </Grid>
        {columnDetails && mensagem && (
          <Grid item xs={12} sm={12} md={4}>
            <Grid container justifyContent="center">
              {mensagem.respostaComAnexo && (
                <MensagemResposta
                  idMensagem={mensagem.id ?? 0}
                  resposta={
                    mensagem.respostas ? mensagem.respostas[0] : undefined
                  }
                  permitirResposta={
                    !mensagem.respostaAte ||
                    differenceInMinutes(
                      parseISO(mensagem.respostaAte.toString()),
                      Date.now()
                    ) >= 0
                  }
                />
              )}
              {mensagem.perguntasFormatadas &&
                mensagem.perguntasFormatadas.length > 0 && (
                  <MensagemPerguntaFormatada
                    idMensagem={mensagem.id ?? 0}
                    perguntasFormatadas={mensagem.perguntasFormatadas}
                    respostasFormatadas={mensagem.respostasFormatadas}
                    permitirResposta={
                      !mensagem.respostaAte ||
                      differenceInMinutes(
                        parseISO(mensagem.respostaAte.toString()),
                        Date.now()
                      ) >= 0
                    }
                  />
                )}
            </Grid>
          </Grid>
        )}
        {mensagem &&
          mensagem.permiteResposta &&
          mensagem.perguntasFormatadas?.length == 0 && (
            <ChatMensagem mensagem={mensagem} />
          )}
      </Grid>
    </Main>
  );
};
