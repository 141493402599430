import React, { FC } from "react";
import { EMensagemCategoriaTipo, IMensagem } from "@deltasge/marauders-map";

import {
  Badge,
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Heart as CurtidaIcon } from "mdi-material-ui";
import { format, parseISO } from "date-fns";
import { DetalhesAnexos } from "./DetalhesAnexos";
import { Imagem } from "components/Imagem";
import { useMobile } from "hooks/useMobile";
import { LinearProgressWithLabel } from "components/LinearProgressWithLabel";

export const TabDetalhes: FC<{
  mensagem: IMensagem;
}> = ({ mensagem }) => {
  const theme = useTheme();
  const isSm = useMobile("sm");
  const isMd = useMobile("md");

  const qtdeCurtidas = (mensagem.mensagemCurtidas ?? []).length;
  let curtidas = "Nenhuma curtida";
  if (qtdeCurtidas == 1) curtidas = "Uma curtida";
  else if (qtdeCurtidas > 1) curtidas = `${qtdeCurtidas} curtidas`;

  // TODO: Criar progresso de entrega
  let progress: number | undefined = undefined;
  if (
    mensagem.permiteResposta &&
    ((mensagem.perguntasFormatadas ?? []).length > 0 ||
      mensagem.respostaComAnexo)
  ) {
    progress = undefined; // Math.floor(Math.random() * (100 - 0 + 1) + 0);
  }

  return (
    <Grid container>
      <Grid item xs={9}>
        {mensagem.titulo && (
          <Typography variant="h5" noWrap>
            {mensagem.titulo}
          </Typography>
        )}
        <Typography variant="subtitle1" noWrap>
          {mensagem.mensagemCategoria?.nome}
        </Typography>
      </Grid>
      {progress && (
        <Grid item xs={2} textAlign="right">
          <Typography variant="caption">
            Até o momento foram <b>respondidas 99</b> de <b>999</b>
          </Typography>
          <LinearProgressWithLabel variant="determinate" value={progress} />
        </Grid>
      )}
      <Grid item xs textAlign="right" p={1}>
        <Tooltip title={curtidas}>
          <Badge
            badgeContent={qtdeCurtidas}
            color={qtdeCurtidas > 0 ? "error" : "default"}
          >
            <CurtidaIcon color={qtdeCurtidas > 0 ? "error" : "disabled"} />
          </Badge>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="caption">
          {mensagem.usuario?.nome ?? mensagem.professor?.nome}
          &nbsp;&bull;&nbsp;
          {mensagem.createdAt
            ? format(
                parseISO(mensagem.createdAt.toString()),
                "dd 'de' MMM. 'de' yyyy"
              )
            : ""}
          {mensagem.updatedAt && mensagem.createdAt != mensagem.updatedAt
            ? format(
                parseISO(mensagem.updatedAt.toString()),
                "' Editado às' dd 'de' MMM. 'de' yyyy"
              )
            : ""}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {mensagem.mensagemAtribuicoes && (
          <Typography
            variant="caption"
            textAlign="right"
            component="p"
            fontWeight="bold"
          >
            Matéria:{" "}
            {mensagem.mensagemAtribuicoes[0].materia?.nome ??
              "TODAS AS MATÉRIAS"}
          </Typography>
        )}
      </Grid>
      {(mensagem.respostaAte || mensagem.enviarEm) && (
        <Grid item xs={12}>
          {mensagem.enviarEm && (
            <Typography variant="caption" sx={{ float: "left" }}>
              Será enviado em:
              {format(
                parseISO(mensagem.enviarEm.toString()),
                " dd 'de' MMM. 'de' yyyy 'às' H:mm"
              )}
            </Typography>
          )}
          {mensagem.respostaAte && (
            <Typography variant="caption" sx={{ float: "right" }}>
              Data da entrega:
              {format(
                parseISO(mensagem.respostaAte.toString()),
                " dd 'de' MMM. 'de' yyyy 'às' H:mm"
              )}
            </Typography>
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <div dangerouslySetInnerHTML={{ __html: mensagem.texto ?? "" }} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {((mensagem.mensagemArquivos ?? []).length > 0 ||
        (mensagem.mensagemLinks ?? []).length > 0) && (
        <Grid item xs={12}>
          {mensagem.mensagemCategoria?.tipo == EMensagemCategoriaTipo.Fotos && (
            <ImageList
              cols={isSm ? 1 : isMd ? 2 : 3}
              rowHeight={parseInt(theme.spacing(20), 10)}
            >
              {(mensagem.mensagemArquivos ?? []).map((foto, index) => (
                <ImageListItem key={`image-${index}`}>
                  <Imagem
                    src={foto.arquivo?.caminhoArquivo}
                    alt={foto.arquivo?.caminhoArquivo ?? ""}
                    style={{ maxHeight: theme.spacing(20), objectFit: "cover" }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )}
          {mensagem.mensagemCategoria?.tipo != EMensagemCategoriaTipo.Fotos && (
            <DetalhesAnexos
              mensagemArquivos={mensagem.mensagemArquivos ?? []}
              mensagemLinks={mensagem.mensagemLinks ?? []}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};
