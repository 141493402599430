import { createAction } from "@reduxjs/toolkit";
import { createActionWithPayload } from "store/helpers";

import { ITheme, LayoutActionsTypes } from "./types";

export const toggleSidebar = createAction<void>(
  LayoutActionsTypes.SIDEBAR_TOGGLE
);
export const toggleRightbar = createAction<boolean | undefined>(
  LayoutActionsTypes.RIGHTBAR_TOGGLE
);
export const toggleTheme = createActionWithPayload<ITheme>(
  LayoutActionsTypes.THEME_TOGGLE
);
export const toggleDevMode = createActionWithPayload<boolean>(
  LayoutActionsTypes.DEVMODE_TOGGLE
);
export const toggleFeedback = createAction<void>(
  LayoutActionsTypes.FEEDBACK_TOGGLE
);
