import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { add, format, formatDistance, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

import {
  IMensagem,
  IMensagemAtribuicao,
  IMensagemLink,
  yupMensagemAtribuicao,
} from "@deltasge/marauders-map";

import PerfectScrollbar from "react-perfect-scrollbar";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  Link as LinkIcon,
  TrayArrowUp as UploadIcon,
  ClockOutline as RelogioIcon,
  Calendar as CalendarIcon,
  ArrowTopRightThinCircleOutline as MensagemIcon,
  Delete as DeleteIcon,
  Pencil as EditIcon,
} from "mdi-material-ui";
import { MobileDatePicker, MobileDateTimePicker } from "@mui/x-date-pickers";

import { Input } from "assets/styleds";
import { snack } from "components/GlobalSnackbar";
import { api, history } from "configs";
import { useMobile } from "hooks/useMobile";
import { useDialog } from "hooks/useDialog";
import { getError, isHTML } from "utils";
import { Anexos, DialogLink } from "pages/Mensagens/cadastro/components";
import {
  IPreMensagemArquivo,
  utilMensagem,
} from "pages/Mensagens/utilMensagem";
import { useConfirmDialog } from "hooks/useDialogConfirm";
import { ToolbarCadastro } from "pages/Mensagens/components";
import { Cadastro } from "pages/Mensagens/cadastro";

export interface IMsgError {
  texto?: string;
  mensagemAtribuicoes?: string;
}

export type IPartAtribuicao = Pick<
  IMensagemAtribuicao,
  "anoLetivo" | "idCurso" | "idSerie" | "idTurma" | "idMateria"
>;

export const Mensagem: FC<{
  mensagem: IMensagem;
  msgError: IMsgError;
  partAtribuicao: IPartAtribuicao;
  setMensagem: Dispatch<SetStateAction<IMensagem | undefined>>;
  setMsgError: Dispatch<SetStateAction<IMsgError>>;
  idAvaliacao?: number;
}> = ({
  mensagem,
  setMensagem,
  msgError,
  setMsgError,
  idAvaliacao,
  partAtribuicao,
}) => {
  const formId = "form-mensagem";

  const [expanded, setExpanded] = useState(
    Object.keys(msgError).length > 0 || !!mensagem.id
  );
  const [link, setLink] = useState<IMensagemLink>();

  const isSm = useMobile("sm");

  const theme = useTheme();

  const {
    show: showLink,
    RenderDialog: RenderLink,
    hide: hideLink,
  } = useDialog({
    title: "Adicione um link",
    options: {
      modal: true,
      maxWidth: "xs",
      scroll: "paper",
    },
  });

  const {
    show: showCadastro,
    RenderDialog: DialogCadastro,
    hide: hideCadastro,
  } = useDialog({
    title: "Editar tarefa de casa",
    customClose: async (hide) => {
      try {
        if (!mensagem?.id) {
          throw new Error("ID da publicação não encontrado");
        }
        const { data } = await api.get<IMensagem | undefined>(
          `educador/mensagem/${mensagem.id}`,
          {
            params: {
              select: utilMensagem.camposPublicacao(),
            },
          }
        );
        if (!data) {
          throw new Error("Erro ao recuperar a publicação");
        }
        setMensagem({ ...data });
        hide();
      } catch (error) {
        snack.error(getError(error));
      }
    },
    RenderToolbar: ({ hide, title }) => (
      <ToolbarCadastro hide={hide} title={title} idForm={formId} />
    ),
    options: {
      fullScreen: true,
      modal: true,
      maxWidth: "lg",
      scroll: "paper",
    },
  });

  const { show: handleDelete, RenderDialog: DeleteDialog } = useConfirmDialog<{
    idAvaliacao: number;
    idMensagem: number;
  }>({
    defaults: {
      title: "Excluir este conteúdo para avaliação?",
    },
    onConfirmed: async (payload, hide) => {
      try {
        await api.delete(
          `avaliacao/${payload.idAvaliacao}/mensagem/${payload.idMensagem}`
        );
        if (expanded) setExpanded(false);
        setMensagem(undefined);
      } catch (error) {
        snack.warning(getError(error));
      } finally {
        hide();
      }
    },
  });

  const handleChangeAtr = ({
    aluno,
    loginExtra,
    professor,
    responsavel,
  }: {
    aluno?: boolean;
    loginExtra?: boolean;
    professor?: boolean;
    responsavel?: boolean;
  }) => {
    try {
      let { mensagemAtribuicoes } = mensagem;
      if (!mensagemAtribuicoes || mensagemAtribuicoes.length == 0) {
        mensagemAtribuicoes = [
          {
            ...yupMensagemAtribuicao.getDefault(),
            idCurso: partAtribuicao.idCurso,
            idSerie: partAtribuicao.idSerie,
            idTurma: partAtribuicao.idTurma,
            idMateria: partAtribuicao.idMateria,
            anoLetivo: partAtribuicao.anoLetivo,
            excluido: false,
            exportado: false,
          },
        ];
      }
      mensagemAtribuicoes[0].aluno =
        aluno == undefined ? mensagemAtribuicoes[0].aluno : aluno;
      mensagemAtribuicoes[0].loginExtra =
        loginExtra == undefined
          ? mensagemAtribuicoes[0].loginExtra
          : loginExtra;
      mensagemAtribuicoes[0].professor =
        professor == undefined ? mensagemAtribuicoes[0].professor : professor;
      mensagemAtribuicoes[0].responsavel =
        responsavel == undefined
          ? mensagemAtribuicoes[0].responsavel
          : responsavel;

      if (aluno || loginExtra || responsavel || professor) {
        delete msgError.mensagemAtribuicoes;
        setMsgError(msgError);
      }
      setMensagem({ ...mensagem, mensagemAtribuicoes });
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const handleLink = (link: IMensagemLink, old?: IMensagemLink): void => {
    let { mensagemLinks } = mensagem;
    if (!mensagemLinks) mensagemLinks = [link];
    else {
      const i = mensagemLinks.findIndex((f) => f.url == (old ?? link).url);
      if (i >= 0) mensagemLinks[i] = link;
      else mensagemLinks.push(link);
    }
    setMensagem({ ...mensagem, mensagemLinks });
    setLink(undefined);
    hideLink();
  };

  if (typeof mensagem.enviarEm == "string") {
    mensagem.enviarEm = parseISO(mensagem.enviarEm);
  }

  if (typeof mensagem.respostaAte == "string") {
    mensagem.respostaAte = parseISO(mensagem.respostaAte);
  }

  const txtMsgHTML = mensagem?.id && isHTML(mensagem?.texto);

  return (
    <Box mb={2}>
      <DialogCadastro>
        {mensagem && mensagem.id && (
          <Cadastro
            idMensagem={mensagem.id}
            formId={formId}
            handleClose={hideCadastro}
            idMensagemCategoria={mensagem.idMensagemCategoria}
          />
        )}
      </DialogCadastro>
      <DeleteDialog />
      <RenderLink>
        <DialogLink
          idMensagem={mensagem.id ?? null}
          link={link}
          set={(newLink) => handleLink(newLink, link)}
        />
      </RenderLink>
      <Accordion
        variant="outlined"
        TransitionProps={{ unmountOnExit: true }}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container>
            <Grid item xs>
              <Typography>Conteúdo da avaliação</Typography>
              <Typography variant="caption" component="p">
                Aqui você poderá definir o conteúdo da avaliação e enviar para
                os alunos e/ou responsáveis da turma
              </Typography>
            </Grid>
            <Grid item textAlign="right">
              <Grid container>
                <Grid item xs>
                  <Tooltip title="Editar tarefa de casa">
                    <div>
                      <IconButton
                        aria-label="editar-tarefa"
                        color="primary"
                        disabled={!mensagem.id}
                        onClick={(e) => {
                          e.stopPropagation();
                          showCadastro();
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item xs>
                  <Tooltip title="Detalhes do conteúdo da avaliação">
                    <div>
                      <IconButton
                        aria-label="detalhes-mensagem"
                        color="primary"
                        disabled={!mensagem.id}
                        onClick={() =>
                          history.push(
                            `/publicacoes/detalhes/${mensagem.id}/tab/1`
                          )
                        }
                      >
                        <MensagemIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item xs>
                  <Tooltip title="Remover conteúdo da avaliação">
                    <div>
                      <IconButton
                        aria-label="remover-mensagem"
                        color="secondary"
                        disabled={!mensagem.id}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (mensagem.id && idAvaliacao) {
                            handleDelete({
                              idMensagem: mensagem.id,
                              idAvaliacao,
                            });
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {!txtMsgHTML && (
              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={3}
                  label="Descrição do conteúdo"
                  value={mensagem.texto}
                  onChange={({ target: { value } }) =>
                    setMensagem({ ...mensagem, texto: value })
                  }
                  error={!!msgError.texto}
                  helperText={msgError.texto}
                />
              </Grid>
            )}
            {txtMsgHTML && (
              <Grid
                item
                xs={12}
                ml={1}
                sx={{ border: `1px solid ${theme.palette.divider}` }}
              >
                <PerfectScrollbar
                  style={{
                    height: `${theme.spacing(15)}`,
                  }}
                >
                  <div
                    style={{ zoom: "70%", paddingRight: `${theme.spacing(1)}` }}
                    dangerouslySetInnerHTML={{ __html: mensagem.texto ?? "" }}
                  />
                </PerfectScrollbar>
              </Grid>
            )}
            <Grid item xs={12}>
              <Paper variant="outlined">
                <Typography variant="caption" component="p" sx={{ p: 0.5 }}>
                  Anexos
                </Typography>
                <Divider />
                <Tooltip title="Criar link">
                  <span>
                    <IconButton
                      sx={{ m: 1 }}
                      aria-label="criar-link"
                      onClick={() => showLink()}
                      disabled={
                        !mensagem.texto || mensagem.texto.trim().length == 0
                      }
                    >
                      <LinkIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Fazer upload de arquivos">
                  <label htmlFor="icon-button-file">
                    <Input
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                      id="icon-button-file"
                      type="file"
                      multiple
                      disabled={
                        !mensagem.texto || mensagem.texto.trim().length == 0
                      }
                      onChange={async ({ target: { files } }) => {
                        if (files != null) {
                          const mensagemArquivos: IPreMensagemArquivo[] =
                            mensagem.mensagemArquivos ?? [];
                          for (const file of files) {
                            mensagemArquivos.push({
                              file,
                              idArquivo: "",
                              idMensagem: mensagem.id ?? null,
                              ordem: 0,
                            });
                          }
                          setMensagem({ ...mensagem, mensagemArquivos });
                        }
                      }}
                    />
                    <IconButton
                      aria-label="upload-arquivo"
                      component="span"
                      disabled={
                        !mensagem.texto || mensagem.texto.trim().length == 0
                      }
                    >
                      <UploadIcon />
                    </IconButton>
                  </label>
                </Tooltip>
                {((mensagem.mensagemArquivos ?? []).length > 0 ||
                  (mensagem.mensagemLinks ?? []).length > 0) && (
                  <Anexos
                    listProps={{
                      dense: true,
                      disablePadding: true,
                      sx: { pr: 1, pl: 1 },
                    }}
                    mensagemArquivos={(mensagem.mensagemArquivos ?? []).filter(
                      (f) => !f.deletedAt
                    )}
                    mensagemLinks={(mensagem.mensagemLinks ?? []).filter(
                      (f) => !f.deletedAt
                    )}
                    set={(key, value) =>
                      setMensagem({ ...mensagem, [key]: value })
                    }
                    handleEditLink={(link) => {
                      setLink(link);
                      showLink();
                    }}
                  />
                )}
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper variant="outlined">
                <FormControl error={!!msgError.mensagemAtribuicoes}>
                  <Typography variant="caption" sx={{ p: 0.5 }}>
                    Enviar para
                  </Typography>

                  <Divider />
                  <FormGroup row={!isSm} sx={{ p: 0.5 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            !mensagem.texto || mensagem.texto.trim().length == 0
                          }
                          checked={
                            mensagem.mensagemAtribuicoes?.at(0)?.aluno ?? false
                          }
                          onChange={({ target: { checked } }) => {
                            handleChangeAtr({ aluno: checked });
                          }}
                        />
                      }
                      label="Alunos"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            !mensagem.texto || mensagem.texto.trim().length == 0
                          }
                          checked={
                            mensagem.mensagemAtribuicoes?.at(0)?.responsavel ??
                            false
                          }
                          onChange={({ target: { checked } }) => {
                            handleChangeAtr({ responsavel: checked });
                          }}
                        />
                      }
                      label="Responsáveis"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            !mensagem.texto || mensagem.texto.trim().length == 0
                          }
                          checked={
                            mensagem.mensagemAtribuicoes?.at(0)?.loginExtra ??
                            false
                          }
                          onChange={({ target: { checked } }) => {
                            handleChangeAtr({ loginExtra: checked });
                          }}
                        />
                      }
                      label="Logins extra do aluno"
                    />
                  </FormGroup>
                  {msgError.mensagemAtribuicoes && (
                    <FormHelperText>
                      {msgError.mensagemAtribuicoes}
                    </FormHelperText>
                  )}
                </FormControl>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper variant="outlined">
                <Typography variant="caption" sx={{ p: 0.5 }}>
                  Programação
                </Typography>
                <Divider />
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={6}>
                    <ListItem
                      secondaryAction={
                        <MobileDatePicker
                          minDate={new Date()}
                          label="Enviar em"
                          value={mensagem.enviarEm}
                          onAccept={(enviarEm) =>
                            setMensagem({ ...mensagem, enviarEm })
                          }
                          onChange={(enviarEm) =>
                            setMensagem({ ...mensagem, enviarEm })
                          }
                          renderInput={(props) => {
                            if (props.InputProps?.readOnly) {
                              return (
                                <Tooltip title="Definir data">
                                  <div>
                                    <IconButton
                                      edge="end"
                                      aria-label="relogio"
                                      disabled={
                                        !mensagem.texto ||
                                        mensagem.texto.trim().length == 0 ||
                                        !mensagem.programarEnvio
                                      }
                                      onClick={(e) => {
                                        if (props.inputProps?.onClick)
                                          props.inputProps?.onClick(
                                            e as React.MouseEvent<
                                              | HTMLInputElement
                                              | HTMLTextAreaElement,
                                              MouseEvent
                                            >
                                          );
                                      }}
                                    >
                                      <CalendarIcon />
                                    </IconButton>
                                  </div>
                                </Tooltip>
                              );
                            } else {
                              return <TextField {...props} />;
                            }
                          }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton
                        disabled={
                          !mensagem.texto || mensagem.texto.trim().length == 0
                        }
                        onClick={() =>
                          setMensagem({
                            ...mensagem,
                            programarEnvio: !mensagem.programarEnvio,
                            enviarEm: !mensagem.programarEnvio
                              ? mensagem.respostaAte
                                ? mensagem.respostaAte
                                : add(new Date(), { days: 7 })
                              : null,
                          })
                        }
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={mensagem.programarEnvio}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            mensagem.programarEnvio
                              ? "Agendada para enviar"
                              : "Agendar envio"
                          }
                          secondary={
                            mensagem.enviarEm
                              ? `${formatDistance(
                                  mensagem.enviarEm,
                                  Date.now(),
                                  {
                                    addSuffix: true,
                                    locale: ptBR,
                                  }
                                )} - ${format(mensagem.enviarEm, "dd/MM/yyyy")}`
                              : undefined
                          }
                          secondaryTypographyProps={{
                            variant: "caption",
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ListItem
                      secondaryAction={
                        <MobileDateTimePicker
                          minDateTime={mensagem.enviarEm ?? new Date()}
                          label="Responder até"
                          value={mensagem.respostaAte}
                          onAccept={(respostaAte) =>
                            setMensagem({ ...mensagem, respostaAte })
                          }
                          onChange={(respostaAte) =>
                            setMensagem({ ...mensagem, respostaAte })
                          }
                          renderInput={(props) => {
                            if (props.InputProps?.readOnly) {
                              return (
                                <Tooltip title="Definir data de entrega">
                                  <div>
                                    <IconButton
                                      edge="end"
                                      aria-label="relogio"
                                      disabled={
                                        !mensagem.texto ||
                                        mensagem.texto.trim().length == 0 ||
                                        !mensagem.permiteResposta
                                      }
                                      onClick={(e) => {
                                        if (props.inputProps?.onClick)
                                          props.inputProps?.onClick(
                                            e as React.MouseEvent<
                                              | HTMLInputElement
                                              | HTMLTextAreaElement,
                                              MouseEvent
                                            >
                                          );
                                      }}
                                    >
                                      <RelogioIcon />
                                    </IconButton>
                                  </div>
                                </Tooltip>
                              );
                            } else {
                              return <TextField {...props} />;
                            }
                          }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton
                        disabled={
                          !mensagem.texto || mensagem.texto.trim().length == 0
                        }
                        onClick={() => {
                          setMensagem({
                            ...mensagem,
                            permiteResposta: !mensagem.permiteResposta,
                            respostaComAnexo: mensagem.permiteResposta
                              ? mensagem.respostaComAnexo
                              : false,
                            respostaAte: mensagem.permiteResposta
                              ? null
                              : mensagem.respostaAte,
                            notificaResposta: !mensagem.permiteResposta
                              ? false
                              : mensagem.notificaResposta,
                          });
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={mensagem.permiteResposta}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Permitir resposta"
                          secondary={
                            mensagem.respostaAte
                              ? `Até: ${format(
                                  mensagem.respostaAte,
                                  "dd/MM/yyyy HH:mm"
                                )}`
                              : "Pode ser entregue a qualquer momento após o envio"
                          }
                          secondaryTypographyProps={{
                            variant: "caption",
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Grid>
                  {mensagem.permiteResposta && (
                    <Grid item xs={12} sm={6}>
                      <ListItem disablePadding>
                        <ListItemButton
                          disabled={
                            !mensagem.texto || mensagem.texto.trim().length == 0
                          }
                          onClick={() => {
                            const notificaResposta = !mensagem.notificaResposta;
                            setMensagem({
                              ...mensagem,
                              notificaResposta,
                            });
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={mensagem.notificaResposta}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText primary="Receber notificação de resposta" />
                        </ListItemButton>
                      </ListItem>
                    </Grid>
                  )}
                  {mensagem.permiteResposta && (
                    <Grid item xs={12} sm={6}>
                      <ListItem disablePadding>
                        <ListItemButton
                          disabled={
                            !mensagem.texto || mensagem.texto.trim().length == 0
                          }
                          onClick={() => {
                            let respostaComAnexo = !mensagem.respostaComAnexo;
                            if (
                              (mensagem.perguntasFormatadas ?? []).length > 0 &&
                              !mensagem.respostaComAnexo
                            ) {
                              snack.warning(
                                "Respostas formatadas não podem ser usadas com anexos de resposta"
                              );
                              respostaComAnexo = !respostaComAnexo;
                            }
                            setMensagem({
                              ...mensagem,
                              respostaComAnexo,
                            });
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={mensagem.respostaComAnexo}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText primary="Permitir resposta com anexo" />
                        </ListItemButton>
                      </ListItem>
                    </Grid>
                  )}
                  {mensagem.permiteResposta && (
                    <Grid item xs={12} sm={6}>
                      <Box p={1}>
                        <Autocomplete
                          disabled={mensagem.respostaComAnexo}
                          multiple
                          value={(mensagem.perguntasFormatadas ?? [])
                            .filter((f) => !f.deletedAt)
                            .map((m) => m.nome)}
                          options={(mensagem.perguntasFormatadas ?? [])
                            .filter((f) => !f.deletedAt)
                            .map((m) => m.nome)}
                          onChange={(e, value) => {
                            let deleted = (
                              mensagem.perguntasFormatadas ?? []
                            ).filter((f) => !value.includes(f.nome) && !!f.id);
                            deleted = deleted.map((m) => {
                              m.deletedAt = new Date();
                              return m;
                            });

                            const perguntasFormatadas = value.map((m) => {
                              const pergunta = (
                                mensagem.perguntasFormatadas ?? []
                              ).find((f) => f.nome == m);
                              if (pergunta && pergunta.id) return pergunta;
                              else
                                return {
                                  idMensagem: mensagem.id ?? null,
                                  nome: m,
                                };
                            });

                            setMensagem({
                              ...mensagem,
                              perguntasFormatadas: [
                                ...deleted,
                                ...perguntasFormatadas,
                              ],
                            });
                          }}
                          freeSolo
                          renderTags={(value: readonly string[], getTagProps) =>
                            value.map((option: string, index: number) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                                key={`chip-${index}`}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Adicione opções de resposta"
                              placeholder="Adicione as opções para respostas formatadas"
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
