import React, { Dispatch, FC, SetStateAction } from "react";
import { ptBR } from "date-fns/locale";
import { format, formatDistance, parseISO } from "date-fns";
import {
  IMensagem,
  IMensagemAtribuicaoDestinatario,
} from "@deltasge/marauders-map";

import {
  Checkbox,
  Chip,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AccountCheck as EntregueIcon,
  AccountMinus as DevolvidoIcon,
  AccountArrowRight as AtribuidoIcon,
  Message as MessageIcon,
  EyeOutline as VistoIcon,
  Heart as CurtirIcon,
} from "mdi-material-ui";
import { history } from "configs";
import { Avatar } from "components/Avatar";
import { useParams } from "react-router-dom";

export const ListItemDestinatario: FC<{
  destinatario: IMensagemAtribuicaoDestinatario;
  mensagem: IMensagem;
  destinatarioEscolhidos: IMensagemAtribuicaoDestinatario[];
  escolherDestinatario: boolean;
  setDestinatarioEscolhidos: Dispatch<
    SetStateAction<IMensagemAtribuicaoDestinatario[]>
  >;
}> = ({
  destinatario,
  mensagem,
  escolherDestinatario,
  destinatarioEscolhidos,
  setDestinatarioEscolhidos,
}) => {
  const { tab } = useParams<{
    tab: string;
  }>();

  let title = "Atribuido";
  let icon = <AtribuidoIcon />;
  if (destinatario.resposta) {
    if (destinatario.resposta.devolvido) {
      title = "Devolvido ";
      icon = <DevolvidoIcon color="warning" />;
    } else {
      title = "Entregue ";
      icon = <EntregueIcon color="success" />;
    }
    title += formatDistance(
      parseISO((destinatario.resposta.updatedAt ?? "") as string),
      new Date(),
      { addSuffix: true, locale: ptBR }
    );
  } else if (mensagem.permiteResposta && !mensagem.respostaComAnexo) {
    title = "Conversar";
    icon = <MessageIcon />;
  }

  const checked =
    destinatarioEscolhidos.find((f) => f.deltaId == destinatario.deltaId) !=
    undefined;

  const disabled =
    destinatario.respostaFormatada != undefined ||
    (destinatario.resposta && destinatario.resposta.devolvido == false);

  return (
    <ListItem component={Paper} variant="outlined" sx={{ marginBottom: 1 }}>
      {escolherDestinatario && (
        <ListItemIcon>
          <Checkbox
            disabled={disabled}
            checked={checked}
            onChange={() => {
              setDestinatarioEscolhidos((p) =>
                checked
                  ? p.filter((f) => f.deltaId != destinatario.deltaId)
                  : p.concat(destinatario)
              );
            }}
          />
        </ListItemIcon>
      )}
      <ListItemAvatar>
        <Avatar displayName={destinatario.nome} src={destinatario.photoUrl} />
      </ListItemAvatar>
      <ListItemText
        primary={
          destinatario.displayName ? (
            <Tooltip title={destinatario.nome}>
              <Typography variant="caption" noWrap>
                {destinatario.codigo
                  .concat(
                    destinatario.nrChamada
                      ? " - ".concat(destinatario.nrChamada).concat(" - ")
                      : " - "
                  )
                  .concat(destinatario.displayName)}
              </Typography>
            </Tooltip>
          ) : (
            destinatario.codigo
              .concat(
                destinatario.nrChamada
                  ? " - ".concat(destinatario.nrChamada).concat(" - ")
                  : " - "
              )
              .concat(destinatario.nome)
          )
        }
        secondary={
          destinatario.detalhe &&
          destinatario.detalhe.codigo &&
          destinatario.detalhe.nome
            ? destinatario.detalhe.codigo
                .concat(
                  destinatario.detalhe.nrChamada
                    ? " - ".concat(destinatario.detalhe.nrChamada).concat(" - ")
                    : " - "
                )
                .concat(destinatario.detalhe.nome)
            : undefined
        }
        primaryTypographyProps={{ variant: "body2", noWrap: true }}
        secondaryTypographyProps={{ noWrap: true }}
      />
      <ListItemSecondaryAction>
        {destinatario.curtida && (
          <Tooltip
            title={`curtiu a publicação em ${format(
              parseISO((destinatario.curtida.updatedAt ?? "") as string),
              "dd 'de' MMM. 'de' yyyy 'às' H:mm"
            )}`}
          >
            <IconButton disableRipple sx={{ cursor: "default" }} color="error">
              <CurtirIcon />
            </IconButton>
          </Tooltip>
        )}
        {destinatario.visualizacao && (
          <Tooltip
            title={"visto ".concat(
              format(
                parseISO((destinatario.visualizacao.updatedAt ?? "") as string),
                "dd 'de' MMM. 'de' yyyy 'às' H:mm"
              )
            )}
          >
            <IconButton disableRipple sx={{ cursor: "default" }}>
              <VistoIcon />
            </IconButton>
          </Tooltip>
        )}
        {destinatario.respostaFormatada && (
          <Tooltip
            title={"respondido ".concat(
              formatDistance(
                parseISO(
                  (destinatario.respostaFormatada.updatedAt ?? "") as string
                ),
                new Date(),
                { addSuffix: true, locale: ptBR }
              )
            )}
          >
            <Chip
              label={destinatario.respostaFormatada?.perguntaFormatada?.nome}
            />
          </Tooltip>
        )}
        {mensagem.permiteResposta && mensagem.perguntasFormatadas?.length == 0 && (
          <Tooltip title={title}>
            <IconButton
              onClick={() =>
                history.push(
                  `/publicacoes/detalhes/${mensagem.id}/tab/${tab}/destinatario/${destinatario.id}`
                )
              }
              edge="end"
              aria-label="chat"
            >
              {icon}
            </IconButton>
          </Tooltip>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};
