import React, { FC, useState, useEffect, useCallback } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import {
  IMensagem,
  IPrepareNotification,
  ENotificationChannel,
  IMensagemAtribuicaoDetalhe,
  IMensagemAtribuicaoDestinatario,
  EUserType,
} from "@deltasge/marauders-map";
import { format, formatDistance, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

import { Box } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { Filter as FilterIcon } from "mdi-material-ui";

import { api, history } from "configs";
import { snack } from "components/GlobalSnackbar";
import { useMobile } from "hooks/useMobile";
import { useConfirmDialog } from "hooks/useDialogConfirm";
import { Main } from "pages/_layouts/Main";
import { utilMensagem as util } from "../utilMensagem";
import { TabDetalhes } from "./components/TabDetalhes";
import { TabContent } from "./components/TabContent";
import { Tabs } from "./components/Tabs";
import { Filter } from "./components/Filter";
import { BtnImprimir } from "./components/BtnImprimir";
import { BtnDelete, BtnEditar } from "./components/BtnsParaDetalhes";
import { useAppSelector } from "store";
import { useDialog } from "hooks/useDialog";
import { DialoagEnviarNotificacao } from "./components/DialogEnviarNotificacao";
import { getError } from "utils";
import { ToolbarCadastro } from "../components";
import { Cadastro } from "../cadastro";

export interface IFilter {
  pergunta: number;
  devolvido?: boolean;
  visualizacao?: boolean;
  custida?: boolean;
}
export interface IParamHandleEscolher {
  limpar?: boolean;
}

export const DetalhesMensagem: FC = () => {
  const { idMensagem, tab, destinatario } = useParams<{
    idMensagem: string;
    tab: string;
    destinatario?: string;
  }>();

  const {
    mensagemCategoria: { data: categorias },
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();
  const formId = "form-mensagem";

  const [hasError, setHasError] = useState(false);
  const [mensagem, setMensagem] = useState<IMensagem | undefined>();

  const [atribuicoes, setAtribuicoes] = useState<IMensagemAtribuicaoDetalhe[]>(
    []
  );

  const [escolherDestinatario, setEscolherDestinatario] = useState(false);
  const [destinatarioEscolhidos, setDestinatarioEscolhidos] = useState<
    IMensagemAtribuicaoDestinatario[]
  >([]);
  const [filter, setFilter] = useState<IFilter>({
    pergunta: 0,
  });
  const [enviandoNotificacao, setEnviandoNotificacao] = useState(false);

  const isSm = useMobile("sm");

  const { show: showDeleteDialog, RenderDialog: DeleteDialog } =
    useConfirmDialog<number>({
      defaults: {
        title: "Excluir a mensagem?",
        content:
          "Os anexos e as respostas também serão excluidos. Esta ação não pode ser revertida!!!",
      },
      onConfirmed: async (payload, hide) => {
        try {
          await api.delete(`educador/mensagem/${payload}`);
          history.push(`/mensagens`);
        } catch (error) {
          snack.warning((error as Error).toString());
        } finally {
          hide();
        }
      },
    });

  const {
    RenderDialog: DialogNotificacao,
    hide: hideDialogNotificacao,
    show: showDialogNotificacao,
  } = useDialog({
    title: "Enviar notificação",
    options: {
      modal: true,
      maxWidth: "sm",
    },
  });

  const {
    show: showCadastro,
    RenderDialog: DialogCadastro,
    hide: hideCadastro,
  } = useDialog({
    title: "Editar publicação",
    customClose: (hide) => {
      getMensagem(parseInt(idMensagem, 10));
      hide();
    },
    RenderToolbar: ({ hide, title }) => (
      <ToolbarCadastro hide={hide} title={title} idForm={formId} />
    ),
    options: {
      fullScreen: true,
      modal: true,
      maxWidth: "lg",
      scroll: "paper",
    },
  });

  const handleEscolherTodos = useCallback(
    (params: IParamHandleEscolher | undefined) => {
      const { limpar = false } = params ?? { limpar: false };
      if (limpar) {
        setDestinatarioEscolhidos([]);
        setEscolherDestinatario(false);
        return;
      }
      setEscolherDestinatario(true);
      setDestinatarioEscolhidos(
        atribuicoes
          .map(({ destinatarios }) => destinatarios)
          .flatMap((m) => m)
          .filter(
            (f) =>
              f.respostaFormatada == undefined &&
              (f.resposta == undefined || f.resposta.devolvido != false)
          )
      );
    },
    [tab, atribuicoes]
  );

  const handleCancelar = useCallback(() => {
    handleEscolherTodos({ limpar: true });
    hideDialogNotificacao();
  }, [handleEscolherTodos]);

  const handleEnviar = useCallback(
    async ({ title, body }: { title: string; body: string }) => {
      try {
        if (!usuario) {
          throw new Error("Não foi possível encontrar o usuário autenticado");
        }
        if (!mensagem || !mensagem.id) {
          throw new Error("Não foi possível encontrar o ID da publicação");
        }

        hideDialogNotificacao();
        setEnviandoNotificacao(true);

        const notifications: IPrepareNotification[] =
          destinatarioEscolhidos.map((dest) => {
            const colCodigo =
              dest.tipoUsuario == EUserType.Aluno
                ? "codigoAluno"
                : dest.tipoUsuario == EUserType.Responsavel
                ? "codigoResponsavel"
                : dest.tipoUsuario == EUserType.LoginExtra
                ? "codigoLoginExtra"
                : dest.tipoUsuario == EUserType.Professor
                ? "codigoProfessor"
                : "codigoUsuario";
            const colId =
              dest.tipoUsuario == EUserType.Aluno
                ? "idAluno"
                : dest.tipoUsuario == EUserType.Responsavel
                ? "idResponsavel"
                : dest.tipoUsuario == EUserType.LoginExtra
                ? "idLoginExtra"
                : dest.tipoUsuario == EUserType.Professor
                ? "idProfessor"
                : "idUsuario";
            return {
              body,
              title,
              toIds: [dest.deltaId],
              to: [],
              fromId: usuario.deltaId,
              data: {
                id: mensagem.id?.toString(),
                idReal: mensagem.id?.toString(),
                channel: ENotificationChannel.academico,
                data: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
                dataNotificacao: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
                descricao: body,
                escolaId: usuario.escolaId,
                modulo: "mensagens",
                remetente: usuario.displayName || usuario.nome,
                titulo: title,
                toId: dest.deltaId,
                [colCodigo]: dest.codigo,
                [colId]: dest.id?.toString(),
                fromId: usuario.deltaId,
              },
            };
          });

        await api.post("notificacao", notifications);
        snack.success("Notificações enviada com sucesso!");
      } catch (error) {
        snack.error(getError(error));
      } finally {
        handleEscolherTodos({ limpar: true });
        setEnviandoNotificacao(false);
      }
    },
    [destinatarioEscolhidos, usuario]
  );

  const getMensagem = useCallback(
    async (id: number) => {
      const msg = await util.getMensagem({
        idMensagem: id,
        cancelToken: source.token,
        para: "detalhes",
      });
      if (msg) {
        msg.mensagemCategoria = categorias.find(
          (f) => f.id == msg.idMensagemCategoria
        );
        setMensagem(msg);
      } else {
        setHasError(true);
      }
    },
    [util.getMensagem]
  );

  useEffect(() => {
    if (idMensagem && !mensagem) {
      getMensagem(parseInt(idMensagem, 10));
    }
    return () => source.cancel();
  }, []);

  const buttons: React.ReactNode[] = [];
  if (
    mensagem &&
    mensagem.permiteResposta &&
    mensagem.perguntasFormatadas &&
    mensagem.perguntasFormatadas.length > 0
  ) {
    buttons.push(<BtnImprimir mensagem={mensagem} />);
  }
  if (tab == "1" && idMensagem) {
    buttons.push(
      <BtnDelete
        handleClick={() => {
          showDeleteDialog(parseInt(idMensagem, 10));
        }}
      />,
      <BtnEditar handleClick={showCadastro} />
    );
  }

  let titleNotification = mensagem?.titulo;
  if (!titleNotification) {
    titleNotification = mensagem?.mensagemCategoria?.nome;
  }
  let bodyNotification = `Você ainda não ${
    mensagem?.permiteResposta && mensagem?.respostaComAnexo
      ? "entregou"
      : "respondeu"
  }.`;
  if (mensagem?.respostaAte) {
    bodyNotification = bodyNotification.concat(
      ` Lembre-se que você tem ${formatDistance(
        parseISO(mensagem.respostaAte.toString()),
        new Date(),
        { locale: ptBR }
      )} para ${
        mensagem?.permiteResposta && mensagem?.respostaComAnexo
          ? "entregar"
          : "responder"
      }`
    );
  }

  return (
    <TabContext value={tab}>
      <DialogCadastro>
        {mensagem && (
          <Cadastro
            idMensagem={parseInt(idMensagem, 10)}
            formId={formId}
            handleClose={hideCadastro}
            idMensagemCategoria={mensagem.idMensagemCategoria}
          />
        )}
      </DialogCadastro>
      <DeleteDialog />
      {mensagem && (
        <DialogNotificacao>
          <DialoagEnviarNotificacao
            handleEnviar={handleEnviar}
            handleCancelar={handleCancelar}
            body={bodyNotification}
            title={`Lembrete da publicação ${titleNotification}`}
          />
        </DialogNotificacao>
      )}
      <Main
        maxWidth={false}
        title="Detalhes da publicação"
        subtitle={mensagem?.titulo || mensagem?.mensagemCategoria?.nome}
        loading={!mensagem && !hasError}
        useMargin={tab == "1"}
        buttons={buttons}
        searchElement={
          isSm
            ? undefined
            : mensagem?.mensagemAtribuicoes && (
                <Tabs
                  tab={tab}
                  mensagemAtribuicoes={mensagem?.mensagemAtribuicoes}
                  idMensagem={mensagem?.id ?? 0}
                />
              )
        }
        rightbarChildren={
          tab != "1" && !!mensagem && !destinatario ? (
            <Filter
              mensagem={mensagem}
              filter={filter}
              setFilter={setFilter}
              escolherDestinatario={escolherDestinatario}
              destinatarioEscolhidos={destinatarioEscolhidos}
              handleEscolherTodos={handleEscolherTodos}
              setEscolherDestinatario={setEscolherDestinatario}
              enviandoNotificacao={enviandoNotificacao}
              handleEnviar={showDialogNotificacao}
            />
          ) : undefined
        }
        rightbarIcon={
          tab != "1" && !!mensagem && !destinatario ? <FilterIcon /> : undefined
        }
      >
        {!hasError && mensagem && mensagem.mensagemAtribuicoes && (
          <Box height="100%">
            {isSm && (
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  tab={tab}
                  mensagemAtribuicoes={mensagem?.mensagemAtribuicoes}
                  idMensagem={mensagem?.id ?? 0}
                />
              </Box>
            )}
            <TabPanel value="1" sx={{ padding: 0 }}>
              <TabDetalhes mensagem={mensagem} />
            </TabPanel>
            {util.showTabDetalhesMensagem({
              atr: mensagem.mensagemAtribuicoes,
              tipo: "aluno",
            }) && (
              <TabPanel
                value="2"
                sx={{
                  padding: 0,
                  height: (theme) =>
                    `calc(100% - ${
                      isSm ? theme.mixins.toolbar.minHeight ?? 0 : 0
                    }px)`,
                }}
              >
                <TabContent
                  mensagemAtribuicoes={mensagem.mensagemAtribuicoes}
                  source={source}
                  setAtribuicoes={setAtribuicoes}
                  atribuicoes={atribuicoes}
                  mensagem={mensagem}
                  filter={filter}
                  setDestinatarioEscolhidos={setDestinatarioEscolhidos}
                  destinatarioEscolhidos={destinatarioEscolhidos}
                  escolherDestinatario={escolherDestinatario}
                />
              </TabPanel>
            )}
            {util.showTabDetalhesMensagem({
              atr: mensagem.mensagemAtribuicoes,
              tipo: "responsavel",
            }) && (
              <TabPanel
                value="3"
                sx={{
                  padding: 0,
                  height: (theme) =>
                    `calc(100% - ${
                      isSm ? theme.mixins.toolbar.minHeight ?? 0 : 0
                    }px)`,
                }}
              >
                <TabContent
                  mensagemAtribuicoes={mensagem.mensagemAtribuicoes}
                  source={source}
                  setAtribuicoes={setAtribuicoes}
                  atribuicoes={atribuicoes}
                  mensagem={mensagem}
                  filter={filter}
                  setDestinatarioEscolhidos={setDestinatarioEscolhidos}
                  destinatarioEscolhidos={destinatarioEscolhidos}
                  escolherDestinatario={escolherDestinatario}
                />
              </TabPanel>
            )}
            {util.showTabDetalhesMensagem({
              atr: mensagem.mensagemAtribuicoes,
              tipo: "loginExtra",
            }) && (
              <TabPanel
                value="4"
                sx={{
                  padding: 0,
                  height: (theme) =>
                    `calc(100% - ${
                      isSm ? theme.mixins.toolbar.minHeight ?? 0 : 0
                    }px)`,
                }}
              >
                <TabContent
                  mensagemAtribuicoes={mensagem.mensagemAtribuicoes}
                  source={source}
                  setAtribuicoes={setAtribuicoes}
                  atribuicoes={atribuicoes}
                  mensagem={mensagem}
                  filter={filter}
                  setDestinatarioEscolhidos={setDestinatarioEscolhidos}
                  destinatarioEscolhidos={destinatarioEscolhidos}
                  escolherDestinatario={escolherDestinatario}
                />
              </TabPanel>
            )}
            {util.showTabDetalhesMensagem({
              atr: mensagem.mensagemAtribuicoes,
              tipo: "professor",
            }) && (
              <TabPanel
                value="5"
                sx={{
                  padding: 0,
                  height: (theme) =>
                    `calc(100% - ${
                      isSm ? theme.mixins.toolbar.minHeight ?? 0 : 0
                    }px)`,
                }}
              >
                <TabContent
                  mensagemAtribuicoes={mensagem.mensagemAtribuicoes}
                  source={source}
                  setAtribuicoes={setAtribuicoes}
                  atribuicoes={atribuicoes}
                  mensagem={mensagem}
                  filter={filter}
                  setDestinatarioEscolhidos={setDestinatarioEscolhidos}
                  destinatarioEscolhidos={destinatarioEscolhidos}
                  escolherDestinatario={escolherDestinatario}
                />
              </TabPanel>
            )}
          </Box>
        )}
      </Main>
    </TabContext>
  );
};
