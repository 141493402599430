import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";

import {
  ChevronDown as ExpandMoreIcon,
  Calculator as RecalculoIcon,
  AccountSync as RemanejadoIcon,
  ClipboardClock as AvaliacaoIcon,
  FileDocumentMultipleOutline as RelNotaGelIcon,
  FileDocumentMultiple as RelMediaGelIcon,
  FileDocumentOutline as RelNotaIcon,
  ChartBox as GraficoTurmaIcon,
  ChartBoxOutline as GraficoAlunoIcon,
  TableSearch as ProvasIcon,
  CheckboxBlankOutline as ConcluirIcon,
  CheckboxMarked as ConcluidoIcon,
  Sync as RecarregarIcon,
} from "mdi-material-ui";
import { RuleFolder as BoletimIcon } from "@mui/icons-material";
import { IFilterDigitacao } from "interfaces";
import { Environment } from "configs";
import { IAvaCtr, ICargaHoraria, IDNColumn } from "@deltasge/marauders-map";
import { snack } from "components/GlobalSnackbar";
import { useAppSelector } from "store";

export const Filter: FC<{
  Component: () => JSX.Element;
  filter: IFilterDigitacao;
  columns: IDNColumn[];
  setColumns: Dispatch<SetStateAction<IDNColumn[]>>;
  avaCtr?: IAvaCtr;
  cargaHoraria?: ICargaHoraria[];
  handleReaload: () => void;
  handleConcluir: (avaCtr: IAvaCtr) => Promise<void>;
  handleBoletim: () => void;
  handleProvas: () => void;
  handleRelNotas: (tipo: "porMateria" | "gerais" | "gerais_bimestre") => void;
  handleAvaliacoes: () => void;
  handleCorrigirRemanejados: () => Promise<void>;
  handleRecalcular: () => Promise<void>;
  handleGrafico: (isAluno?: boolean) => Promise<void>;
  handleUpdateCargaHoraria: (
    id: number,
    payload: Record<string, number>
  ) => Promise<void>;
}> = ({
  columns,
  setColumns,
  Component,
  avaCtr,
  cargaHoraria,
  handleReaload,
  handleConcluir,
  handleBoletim,
  handleProvas,
  handleRelNotas,
  handleAvaliacoes,
  handleCorrigirRemanejados,
  handleGrafico,
  handleRecalcular,
  handleUpdateCargaHoraria,
}) => {
  interface ICargaHorariaAtual {
    id: number;
    aulasDadas: number;
    previstas: number;
    [key: string]: number;
  }
  const [panel, setPanel] = useState({
    columns: false,
  });
  const [concluindo, setConcluindo] = useState(false);
  const [remanejando, setRemanejando] = useState(false);
  const [recalculando, setRecalculando] = useState(false);
  const [loadingGrafTurma, setLoadingGrafTurma] = useState(false);
  const [loadingGrafAluno, setLoadingGrafAluno] = useState(false);
  const [openDialogAulaDada, setOpenDialogAulaDada] = useState(false);
  const [cargaHorariaAtual, setCargaHorariaAtual] =
    useState<ICargaHorariaAtual>({
      id: 0,
      aulasDadas: 0,
      previstas: 0,
    });

  useEffect(() => {
    if (avaCtr && cargaHoraria && cargaHoraria.length > 0) {
      const bimestre = Number(avaCtr.bimestre);
      const aulasDadasKey =
        `aulasDadas${bimestre}` as keyof typeof cargaHoraria[0];
      const previstasKey =
        `previstas${bimestre}` as keyof typeof cargaHoraria[0];

      setCargaHorariaAtual((prevState) => ({
        ...prevState,
        id: Number(cargaHoraria[0].id),
        aulasDadas: Number(cargaHoraria[0][aulasDadasKey]),
        previstas: Number(cargaHoraria[0][previstasKey]),
        [aulasDadasKey]: Number(cargaHoraria[0][aulasDadasKey]),
        [previstasKey]: Number(cargaHoraria[0][previstasKey]),
      }));
    }
  }, [cargaHoraria, avaCtr?.bimestre]);

  const handleAulasChange = (e: any) => {
    const { name, value } = e.target;
    const valorAlterado =
      isNaN(Number(value)) || value === "" ? 0 : Number(value);

    setCargaHorariaAtual((prevState) => ({
      ...prevState,
      [name === "aulasDadas" ? "aulasDadas" : "previstas"]: valorAlterado,
    }));
  };

  const onConcluir = async () => {
    if (!avaCtr) return;
    setConcluindo(true);
    await handleConcluir(avaCtr);
    setConcluindo(false);
  };

  const handleRemanejados = async () => {
    setRemanejando(true);
    await handleCorrigirRemanejados();
    setRemanejando(false);
  };

  const handleRecalculo = async () => {
    setRecalculando(true);
    await handleRecalcular();
    setRecalculando(false);
  };

  const handleOpenGraficoTurma = async () => {
    setLoadingGrafTurma(true);
    await handleGrafico();
    setLoadingGrafTurma(false);
  };

  const handleOpenGraficoAluno = async () => {
    setLoadingGrafAluno(true);
    await handleGrafico(true);
    setLoadingGrafAluno(false);
  };
  const {
    configuracoes: {
      configOnline: { configuracoes: configuracoesCampo },
    },
  } = useAppSelector((state) => state);

  const handleOpenDialogAulaDada = () => {
    setOpenDialogAulaDada(true);
  };
  const handleCloseDialogAulaDada = () => {
    setOpenDialogAulaDada(false);
  };

  return (
    <Box
      sx={{
        width: ({ spacing }) => spacing(Environment.DRAWER_WIDTH),
        display: "block",
      }}
    >
      <Component />
      <Accordion
        elevation={0}
        expanded={panel.columns}
        onChange={() =>
          setPanel((prev) => ({ ...prev, columns: !panel.columns }))
        }
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12} component={Typography} variant="subtitle2">
              Mostrar as colunas
            </Grid>
            <Grid item xs={12} component={Typography} variant="caption">
              {`${columns
                .filter((f) => f.visivel)
                .map((m) => m.nome)
                .join(", ")}`}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {columns.map((column) => (
              <ListItem disablePadding key={`coluna_id_${column.id}`}>
                <ListItemButton
                  onClick={() => {
                    if (
                      columns.filter((e) => e.visivel).length <= 2 &&
                      column.visivel
                    ) {
                      snack.warning(
                        "Você deve deixar ao menos 2 (duas) colunas visíveis"
                      );
                      return;
                    }
                    setColumns(
                      columns.map((current) => {
                        if (current.id == column.id) {
                          current.visivel = !current.visivel;
                        }
                        return current;
                      })
                    );
                  }}
                >
                  <ListItemIcon>
                    <Checkbox checked={column.visivel} disableRipple />
                  </ListItemIcon>
                  <ListItemText
                    primary={column.nome}
                    primaryTypographyProps={{
                      noWrap: true,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <Box pt={1} pl={1} pr={1}>
        <ButtonGroup
          orientation="vertical"
          fullWidth
          sx={{ mb: 1 }}
          color="secondary"
        >
          <Button color="primary" onClick={handleOpenDialogAulaDada}>
            Aulas dadas / previstas
          </Button>
          <Dialog open={openDialogAulaDada} onClose={handleCloseDialogAulaDada}>
            <DialogTitle
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              Aulas dadas / previstas
              <Button
                onClick={handleCloseDialogAulaDada}
                sx={{ maxWidth: 1.2 }}
              >
                X
              </Button>
            </DialogTitle>
            <DialogContent>
              <TextField
                label="Aulas dadas"
                name="aulasDadas"
                value={cargaHorariaAtual.aulasDadas}
                onChange={handleAulasChange}
                id={cargaHorariaAtual.id.toString()}
                variant="filled"
                size="small"
                fullWidth
                sx={{ marginBottom: 2 }}
                disabled={!!configuracoesCampo.gerar_aula_obs}
              />
              <TextField
                label="Aulas previstas"
                name="previstas"
                id={cargaHorariaAtual.id.toString()}
                value={cargaHorariaAtual.previstas}
                onChange={handleAulasChange}
                variant="filled"
                size="small"
                fullWidth
                sx={{ marginBottom: 2 }}
                disabled={!!configuracoesCampo.gerar_aula_obs}
              />
              <Button
                color="primary"
                onClick={() => {
                  const bimestre = Number(avaCtr?.bimestre);
                  const payload = {
                    [`aulasDadas${bimestre}`]: Number(
                      cargaHorariaAtual.aulasDadas
                    ),
                    [`previstas${bimestre}`]: Number(
                      cargaHorariaAtual.previstas
                    ),
                  };
                  handleUpdateCargaHoraria(cargaHorariaAtual.id, payload);
                }}
              >
                Salvar
              </Button>
            </DialogContent>
          </Dialog>
          <Divider />
          <Button
            startIcon={
              concluindo ? (
                <CircularProgress
                  color="secondary"
                  sx={(theme) => ({
                    width: `${theme.spacing(2)} !important`,
                    height: `${theme.spacing(2)} !important`,
                  })}
                />
              ) : avaCtr && avaCtr.concluido ? (
                <ConcluidoIcon />
              ) : (
                <ConcluirIcon />
              )
            }
            disabled={avaCtr ? avaCtr.concluido : true}
            onClick={onConcluir}
          >
            {avaCtr && avaCtr.concluido ? "Concluído" : "Concluir"}
          </Button>
          <Button
            disabled={avaCtr ? avaCtr.concluido : true}
            startIcon={
              recalculando ? (
                <CircularProgress
                  color="secondary"
                  sx={(theme) => ({
                    width: `${theme.spacing(2)} !important`,
                    height: `${theme.spacing(2)} !important`,
                  })}
                />
              ) : (
                <RecalculoIcon />
              )
            }
            onClick={handleRecalculo}
          >
            Recalcular
          </Button>
          <Button
            disabled={avaCtr ? avaCtr.concluido : true}
            startIcon={
              remanejando ? (
                <CircularProgress
                  color="secondary"
                  sx={(theme) => ({
                    width: `${theme.spacing(2)} !important`,
                    height: `${theme.spacing(2)} !important`,
                  })}
                />
              ) : (
                <RemanejadoIcon />
              )
            }
            onClick={handleRemanejados}
          >
            Remanejados
          </Button>
          <Button startIcon={<RecarregarIcon />} onClick={handleReaload}>
            Recarregar
          </Button>
        </ButtonGroup>
        <ButtonGroup orientation="vertical" fullWidth sx={{ mb: 1 }}>
          <Button startIcon={<AvaliacaoIcon />} onClick={handleAvaliacoes}>
            Lista de avaliações
          </Button>
          <Button
            startIcon={<RelNotaIcon />}
            onClick={() => handleRelNotas("porMateria")}
          >
            Relatório de notas
          </Button>
          <Button
            startIcon={<RelNotaGelIcon />}
            onClick={() => handleRelNotas("gerais")}
          >
            Relatório de notas gerais
          </Button>
          <Button
            startIcon={<RelMediaGelIcon />}
            onClick={() => handleRelNotas("gerais_bimestre")}
          >
            Relatório de média gerais
          </Button>
        </ButtonGroup>
        <ButtonGroup orientation="vertical" fullWidth sx={{ mb: 1 }}>
          <Button
            startIcon={
              loadingGrafTurma ? (
                <CircularProgress
                  color="secondary"
                  sx={(theme) => ({
                    width: `${theme.spacing(2)} !important`,
                    height: `${theme.spacing(2)} !important`,
                  })}
                />
              ) : (
                <GraficoTurmaIcon />
              )
            }
            onClick={handleOpenGraficoTurma}
          >
            Gráfico da turma
          </Button>
          <Button
            startIcon={
              loadingGrafAluno ? (
                <CircularProgress
                  color="secondary"
                  sx={(theme) => ({
                    width: `${theme.spacing(2)} !important`,
                    height: `${theme.spacing(2)} !important`,
                  })}
                />
              ) : (
                <GraficoAlunoIcon />
              )
            }
            onClick={handleOpenGraficoAluno}
          >
            Gráfico do aluno
          </Button>
          <Button startIcon={<ProvasIcon />} onClick={handleProvas}>
            Provas do aluno
          </Button>
          <Button startIcon={<BoletimIcon />} onClick={handleBoletim}>
            Boletim do aluno
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};
