import React, { FC, useState, useEffect, useRef } from "react";
import Axios from "axios";
import { Editor as TinyMce } from "tinymce";
import { Editor } from "@tinymce/tinymce-react";

import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Checkbox,
  DialogContent,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  IconButton,
  Tooltip,
  FormControlLabel,
  ListItemIcon,
  InputAdornment,
  CircularProgress,
  Divider,
  Paper,
  Chip,
  ListItem,
  FormHelperText,
  Backdrop,
  InputLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import {
  EMensagemCategoriaTipo,
  EMensagemDestinatario,
  EUserType,
  IFiltroMateria,
  IFiltroTurma,
  IMensagem,
  IMensagemLink,
  IMensagemPerguntaFormatada,
  IUser,
  schemaMensagem,
  utils,
  yupMensagem,
} from "@deltasge/marauders-map";
import {
  Link as LinkIcon,
  TrayArrowUp as UploadIcon,
  Plus as AddIcon,
} from "mdi-material-ui";

import { snack } from "components/GlobalSnackbar";
import { useDialog } from "hooks/useDialog";
import { useMobile } from "hooks/useMobile";
import { useAppSelector } from "store";
import { getError } from "utils";
import { Anexos, DialogLink, DialogPergunta } from "./components";
import { Form, Input } from "assets/styleds";
import {
  IGenericRecipient,
  IPreMensagemArquivo,
  utilMensagem as util,
} from "../utilMensagem";
import { useForm } from "hooks/useForm";
import { fixFormControlLabel } from "assets/themes";
import { AlbumFotos } from "./components/AlbumFotos";
import { logEvent } from "firebase/analytics";
import { analytics } from "App";

export const CadastroMensagem: FC<{
  mensagem?: IMensagem;
  permiteResposta?: boolean;
  programarEnvio?: boolean;
  formId: string;
  handleClose: () => void;
  idMensagemCategoria: number;
}> = ({
  mensagem,
  permiteResposta,
  programarEnvio,
  formId,
  handleClose,
  idMensagemCategoria,
}) => {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  const {
    treeView,
    escola,
    usuario: { usuario },
    mensagemCategoria: { data: mensagemCategorias },
  } = useAppSelector((state) => state);

  const isProfessor = usuario?.tipoUsuario == EUserType.Professor;

  //#region States
  const [tiposAtribuicoes, setTiposAtribuicoes] = useState<
    EMensagemDestinatario[]
  >(util.getDestinatario({ atribuicoes: mensagem?.mensagemAtribuicoes }));
  const [turmasSelecionadas, setTurmasSelecionadas] = useState<IFiltroTurma[]>(
    []
  );
  const [materias, setMaterias] = useState<IFiltroMateria[]>([]);
  const [materia, setMateria] = useState<IFiltroMateria>();
  const [materiaErro, setMateriaErro] = useState<string>();
  const [tipoAtrErro, setTipoAtrErro] = useState<string>();
  const [atrErro, setAtrErro] = useState<string>();
  const [ids, setIds] = useState<number[]>([]);
  const [destinatarios, setDestinatarios] = useState<IGenericRecipient[]>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [perguntaFormatada, setPerguntaFormatada] =
    useState<IMensagemPerguntaFormatada>();
  const [link, setLink] = useState<IMensagemLink>();
  const [editorStyle, setEditorStyle] = useState<string>();
  const editorRef = useRef<TinyMce | null>(null);
  //#endregion

  const turmas = utils.getTurmasTreeView({ cursos: treeView.cursos });
  const mensagemCategoria = mensagemCategorias.find(
    (f) => f.id == idMensagemCategoria
  );

  const {
    show: showPergunta,
    RenderDialog: RenderPergunta,
    hide: hidePergunta,
  } = useDialog({
    title: "Adicione uma resposta",
    options: {
      modal: true,
      maxWidth: "xs",
      scroll: "paper",
    },
  });

  const {
    show: showLink,
    RenderDialog: RenderLink,
    hide: hideLink,
  } = useDialog({
    title: "Adicione um link",
    options: {
      modal: true,
      maxWidth: "xs",
      scroll: "paper",
    },
  });

  const { data, handleChange, handleSubmit, errors, setValue } =
    useForm<IMensagem>({
      trackSubmitter: true,
      initialValues: {
        ...yupMensagem.getDefault(),
        permiteResposta,
        programarEnvio,
        idMensagemCategoria,
        ...mensagem,
      },
      schema: schemaMensagem,
      onSubmit: async (payload, submitter) => {
        if (
          submitter &&
          typeof submitter.value == "string" &&
          submitter.value == "rascunho"
        ) {
          payload.rascunho = true;
        } else {
          payload.rascunho = false;
        }

        if (tiposAtribuicoes.length == 0) {
          setTipoAtrErro(
            "Você deve selecionar ao menos um tipo de destinatário"
          );
          return;
        } else setTipoAtrErro(undefined);

        if (!materia && isProfessor) {
          setMateriaErro("Selecione uma matéria");
          return;
        } else setMateriaErro(undefined);

        if (
          !payload.mensagemAtribuicoes ||
          payload.mensagemAtribuicoes.length == 0
        ) {
          setAtrErro("Você deve selecionar ao menos uma turma");
        } else setAtrErro(undefined);

        if (!payload.permiteResposta && payload.notificaResposta) {
          payload.notificaResposta = false;
        }

        setSaving(true);
        await util.handleSubmitMensagem({
          payload,
          cancelToken: source.token,
          handleClose,
          usuario: usuario as IUser,
        });
        if (mensagemCategoria) {
          logEvent(analytics, "select_content", {
            content_type: "publicacao_categoria",
            content_id: mensagemCategoria.nome,
          });
        }
        logEvent(analytics, "select_content", {
          content_type: "publicacao_enviar_para",
          content_id: tiposAtribuicoes
            .map((m) => EMensagemDestinatario[m])
            .join(", "),
        });

        setSaving(false);
      },
    });

  useEffect(() => {
    const buildAtribuicao = async () => {
      try {
        if (turmasSelecionadas.length == 1 && tiposAtribuicoes.length == 1) {
          setLoading(true);
          setDestinatarios(
            await util.getDestinatarios({
              destinatario: tiposAtribuicoes[0],
              turmas: turmasSelecionadas,
              cancelToken: source.token,
            })
          );
          setMaterias(
            turmas.find((f) => f.id == turmasSelecionadas[0].id)?.materias ?? []
          );
          setLoading(false);
        } else {
          const materias = util.getMateriasBySeries(turmasSelecionadas);
          setMaterias(materias);
          if (materias.length == 0) setMateria(undefined);

          setDestinatarios([]);
        }

        const mensagemAtribuicoes = util.buildMensagemAtribuicoes({
          anoLetivo: escola.anoSite,
          tiposAtribuicoes,
          turmasSelecionadas,
          materia,
          ids: ids.length == destinatarios.length ? [] : [...new Set(ids)],
          idMensagem: data.id ?? null,
        });

        setValue("mensagemAtribuicoes", mensagemAtribuicoes);
      } catch (err) {
        if (!Axios.isCancel(err)) {
          snack.warning(getError(err));
        }
        setLoading(false);
      }
    };

    buildAtribuicao();
    return () => source.cancel();
  }, [tiposAtribuicoes, turmasSelecionadas, materia, ids]);

  useEffect(() => {
    if (
      mensagem?.mensagemAtribuicoes &&
      mensagem.mensagemAtribuicoes.length > 0 &&
      turmasSelecionadas.length == 0
    ) {
      const prevTurmas: IFiltroTurma[] = [];
      (mensagem?.mensagemAtribuicoes ?? []).forEach((atr) => {
        const t = turmas.filter(
          (f) =>
            (f.idCurso == atr.idCurso || atr.idCurso == null) &&
            (f.idSerie == atr.idSerie || atr.idSerie == null) &&
            (f.id == atr.idTurma || atr.idTurma == null)
        );
        prevTurmas.push(...t);
      });
      setTurmasSelecionadas(prevTurmas);

      // esta editando e tem que buscar os destinatarios selecionados
      if (
        mensagem?.id &&
        mensagem?.mensagemAtribuicoes?.length == 1 &&
        ids.length == 0
      ) {
        const mensagemAtribuicao = mensagem.mensagemAtribuicoes.at(0);
        if (mensagemAtribuicao) {
          const idsSelecionados = util.buildDestinatariosSelecionados({
            mensagemAtribuicao,
          });
          if (idsSelecionados.length > 0) setIds(idsSelecionados);
        }
      }
    }
  }, [mensagem?.mensagemAtribuicoes]);

  useEffect(() => {
    if (
      mensagem?.mensagemAtribuicoes &&
      mensagem.mensagemAtribuicoes.length > 0 &&
      !materia &&
      materias.length > 0
    ) {
      const atr = mensagem.mensagemAtribuicoes[0];
      setMateria(materias.find((f) => f.id == atr.idMateria));
    }

    if (!editorStyle) {
      (async () => {
        const response = await fetch(
          `${process.env.PUBLIC_URL}/tinymce-styles.css`
        );
        const editorStyle = await response.text();
        setEditorStyle(editorStyle);
      })();
    }
  }, [mensagem?.mensagemAtribuicoes, materias]);

  const isMd = useMobile("md");

  return (
    <Form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(e);
      }}
      id={formId}
      sx={{ overflow: "hidden" }}
    >
      <RenderPergunta>
        <DialogPergunta
          idMensagem={data.id ?? null}
          pergunta={perguntaFormatada}
          set={(pergunta) =>
            util.handlePerguntaFormatada({
              hideDialog: hidePergunta,
              pergunta,
              setValue,
              perguntasFormatadas: data.perguntasFormatadas,
              oldPergunta: perguntaFormatada,
            })
          }
        />
      </RenderPergunta>
      <RenderLink>
        <DialogLink
          idMensagem={data.id ?? null}
          link={link}
          set={(newLink) =>
            util.handleLink({
              hideDialog: hideLink,
              link: newLink,
              setValue,
              links: data.mensagemLinks,
              oldLink: link,
            })
          }
        />
      </RenderLink>
      <PerfectScrollbar>
        <DialogContent dividers style={{ paddingBottom: 0 }}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={7}>
              <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12}>
                  <TextField
                    value={data.titulo}
                    onChange={handleChange("titulo")}
                    label={schemaMensagem.fields.titulo.spec.label}
                    helperText={errors.titulo}
                    error={!!errors.titulo}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl error={!!errors.texto}>
                    <Paper
                      variant="outlined"
                      sx={(theme) => ({
                        "& .tox-tinymce": {
                          zIndex: `${
                            theme.zIndex.drawer + (isMd ? 0 : 1)
                          } !important`,
                          border: "none",
                          fontFamily: theme.typography.fontFamily,
                        },
                      })}
                    >
                      <Editor
                        tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        onBlur={(evt, editor) => {
                          setValue("texto", editor.getContent());
                        }}
                        onSetContent={(evt, editor) => {
                          setValue("texto", editor.getContent());
                        }}
                        initialValue={
                          data.texto ?? mensagem?.texto ?? undefined
                        }
                        init={{
                          placeholder: schemaMensagem.fields.texto.spec.label,
                          browser_spellcheck: true,
                          language_url: `${process.env.PUBLIC_URL}/tinymce_language_pt_BR.js`,
                          language: "pt_BR",
                          height: "300px",
                          menubar: false,
                          plugins: ["anchor", "autolink", "link", "lists"],
                          toolbar:
                            "undo redo | " +
                            "bold italic underline forecolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist " +
                            "removeformat | link",
                          content_style: editorStyle,
                          promotion: false,
                          branding: false,
                          resize: false,
                          contextmenu: false,
                        }}
                      />
                    </Paper>
                    {errors.texto && (
                      <FormHelperText>{errors.texto}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  {mensagemCategoria?.tipo != EMensagemCategoriaTipo.Fotos && (
                    <Tooltip title="Criar link">
                      <IconButton
                        aria-label="criar-link"
                        onClick={() => {
                          setLink(undefined);
                          showLink();
                        }}
                      >
                        <LinkIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Fazer upload de arquivos">
                    <label htmlFor="icon-button-file">
                      <Input
                        accept={"image/*".concat(
                          mensagemCategoria?.tipo !=
                            EMensagemCategoriaTipo.Fotos
                            ? ", application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                            : ""
                        )}
                        id="icon-button-file"
                        type="file"
                        multiple
                        onChange={async ({ target: { files } }) => {
                          if (files != null) {
                            const mensagemArquivos: IPreMensagemArquivo[] =
                              data.mensagemArquivos ?? [];
                            let ordem = 0;
                            for (const file of files) {
                              mensagemArquivos.push({
                                file,
                                idArquivo: "",
                                idMensagem: data.id ?? null,
                                ordem,
                              });
                              ordem++;
                            }
                            setValue("mensagemArquivos", mensagemArquivos);
                          }
                        }}
                      />
                      <IconButton aria-label="upload-arquivo" component="span">
                        <UploadIcon />
                      </IconButton>
                    </label>
                  </Tooltip>
                </Grid>
                {(data.mensagemArquivos || data.mensagemLinks) && (
                  <Grid item xs={12}>
                    {mensagemCategoria?.tipo ==
                      EMensagemCategoriaTipo.Fotos && (
                      <AlbumFotos
                        fotos={
                          data.mensagemArquivos
                            ? (
                                data.mensagemArquivos as IPreMensagemArquivo[]
                              ).map((m) => ({
                                ...m,
                                id: m.file
                                  ? URL.createObjectURL(m.file)
                                  : m.arquivo?.caminhoArquivo ?? "",
                              }))
                            : []
                        }
                        set={setValue}
                      />
                    )}
                    {mensagemCategoria?.tipo !=
                      EMensagemCategoriaTipo.Fotos && (
                      <Anexos
                        mensagemArquivos={(data.mensagemArquivos ?? [])?.filter(
                          (f) => !f.deletedAt
                        )}
                        mensagemLinks={(data.mensagemLinks ?? []).filter(
                          (f) => !f.deletedAt
                        )}
                        set={setValue}
                        handleEditLink={(link) => {
                          setLink(link);
                          showLink();
                        }}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
            {!isMd && (
              <Divider
                orientation="vertical"
                flexItem
                sx={{ paddingLeft: 2 }}
              />
            )}
            <Grid item sm={12} md>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={12} lg={6}>
                  <FormControl error={!!atrErro}>
                    <InputLabel id="turmas" shrink sx={fixFormControlLabel}>
                      Turmas
                    </InputLabel>
                    <Select
                      labelId="turmas"
                      displayEmpty
                      multiple
                      value={turmasSelecionadas.map((m) => m.id ?? 0)}
                      renderValue={(selected) =>
                        util.renderSelectTurmas(selected, turmas)
                      }
                      onChange={({ target: { value } }) => {
                        util.handleChangeTurmas({
                          selecionadas: turmasSelecionadas,
                          set: setTurmasSelecionadas,
                          turmas,
                          value: value as number[],
                        });
                        setIds([]);
                        setAtrErro(
                          (value as number[]).length == 0
                            ? "Você deve selecionar ao menos uma turma"
                            : undefined
                        );
                      }}
                    >
                      <MenuItem value={-1}>
                        <Checkbox
                          checked={turmasSelecionadas.length == turmas.length}
                        />
                        <ListItemText
                          primary="TODAS AS TURMAS"
                          primaryTypographyProps={{
                            fontWeight: "bold",
                          }}
                        />
                      </MenuItem>
                      {turmas.map((turma) => (
                        <MenuItem key={turma.id} value={turma.id}>
                          <Checkbox
                            checked={
                              turmasSelecionadas.find(
                                (f) => f.id == turma.id
                              ) != undefined
                            }
                          />
                          <ListItemText primary={turma.tituloTurma} />
                        </MenuItem>
                      ))}
                    </Select>
                    {!!atrErro && <FormHelperText>{atrErro}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={12} lg={6}>
                  <FormControl
                    error={
                      !!materiaErro ||
                      (materias.length == 0 &&
                        isProfessor &&
                        turmasSelecionadas.length > 0)
                    }
                  >
                    <InputLabel id="materia" shrink sx={fixFormControlLabel}>
                      Matéria
                    </InputLabel>
                    <Select
                      labelId="materia"
                      displayEmpty
                      value={(materia?.id ?? "").toString()}
                      IconComponent={
                        loading
                          ? () => (
                              <InputAdornment
                                position="end"
                                sx={{
                                  pr: 1,
                                }}
                              >
                                <CircularProgress size={30} />
                              </InputAdornment>
                            )
                          : undefined
                      }
                      onChange={({ target: { value } }) => {
                        if (
                          tiposAtribuicoes.includes(
                            EMensagemDestinatario.Professores
                          ) &&
                          value
                        ) {
                          snack.warning(
                            'Ao enviar para professores a matéria deve ser "Independente da matéria"'
                          );
                          setMateria(undefined);
                          return;
                        }
                        setMateria(
                          materias.find((f) => parseInt(value, 10) == f.id)
                        );
                      }}
                    >
                      <MenuItem value="">
                        {isProfessor
                          ? "Selecione uma matéria"
                          : "Independente da matéria"}
                      </MenuItem>
                      {materias.map((m, i) => (
                        <MenuItem key={`materia-${i}`} value={m.id ?? 0}>
                          {m.nome}
                        </MenuItem>
                      ))}
                    </Select>
                    {materias.length == 0 &&
                      isProfessor &&
                      turmasSelecionadas.length > 0 && (
                        <FormHelperText>
                          Sua lista de turmas contem mais de uma matéria
                        </FormHelperText>
                      )}
                    {!!materiaErro && (
                      <FormHelperText>{materiaErro}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={12} lg={6}>
                  <FormControl
                    component="fieldset"
                    variant="standard"
                    error={!!tipoAtrErro}
                  >
                    <FormLabel component="legend">Enviar para</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={tiposAtribuicoes.includes(
                              EMensagemDestinatario.Alunos
                            )}
                            onChange={() => {
                              setTiposAtribuicoes(
                                util.handleChangeTipoAtribuicao(
                                  EMensagemDestinatario.Alunos,
                                  tiposAtribuicoes
                                )
                              );
                              setTipoAtrErro(undefined);
                            }}
                          />
                        }
                        label="Alunos"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={tiposAtribuicoes.includes(
                              EMensagemDestinatario.Responsáveis
                            )}
                            onChange={() => {
                              setTiposAtribuicoes(
                                util.handleChangeTipoAtribuicao(
                                  EMensagemDestinatario.Responsáveis,
                                  tiposAtribuicoes
                                )
                              );
                              setTipoAtrErro(undefined);
                            }}
                          />
                        }
                        label="Responsáveis"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={tiposAtribuicoes.includes(
                              EMensagemDestinatario["Logins extra do Aluno"]
                            )}
                            onChange={() => {
                              setTiposAtribuicoes(
                                util.handleChangeTipoAtribuicao(
                                  EMensagemDestinatario[
                                    "Logins extra do Aluno"
                                  ],
                                  tiposAtribuicoes
                                )
                              );
                              setTipoAtrErro(undefined);
                            }}
                          />
                        }
                        label="Logins extra do aluno"
                      />
                      {usuario?.tipoUsuario != "P" && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={tiposAtribuicoes.includes(
                                EMensagemDestinatario.Professores
                              )}
                              onChange={() => {
                                setTiposAtribuicoes(
                                  util.handleChangeTipoAtribuicao(
                                    EMensagemDestinatario.Professores,
                                    tiposAtribuicoes
                                  )
                                );
                                setTipoAtrErro(undefined);
                              }}
                            />
                          }
                          label="Professores"
                        />
                      )}
                    </FormGroup>
                    {tipoAtrErro && (
                      <FormHelperText>{tipoAtrErro}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={12} lg={6}>
                  <FormControl>
                    <InputLabel
                      id="destinatarios"
                      shrink
                      sx={fixFormControlLabel}
                    >
                      Destinatarios
                    </InputLabel>
                    <Select
                      labelId="destinatarios"
                      multiple
                      displayEmpty
                      value={ids}
                      IconComponent={
                        loading
                          ? () => (
                              <InputAdornment
                                position="end"
                                sx={{
                                  pr: 1,
                                }}
                              >
                                <CircularProgress size={30} />
                              </InputAdornment>
                            )
                          : undefined
                      }
                      renderValue={(selected) =>
                        util.renderSelectDestinatarios(
                          selected,
                          destinatarios,
                          turmasSelecionadas
                        )
                      }
                      // onChange={(e) => {
                      //   // eslint-disable-next-line no-console
                      //   console.log(e);
                      //   setIds(e.target.value as number[]);
                      // }}
                    >
                      <MenuItem
                        onClick={(e) => {
                          e.preventDefault();
                          if (destinatarios.length == ids.length) {
                            setIds([]);
                          } else {
                            setIds(destinatarios.map((m) => m.value));
                          }
                        }}
                      >
                        <Checkbox
                          indeterminate={
                            ids.length < destinatarios.length && ids.length > 0
                          }
                          checked={
                            destinatarios.length == ids.length ? true : false
                          }
                        />
                        <ListItemText
                          primary={`TODOS OS DESTINATÁRIOS`}
                          primaryTypographyProps={{
                            fontWeight: "bold",
                          }}
                        />
                      </MenuItem>
                      {destinatarios.map((destinatario, index) => (
                        <MenuItem
                          key={`${destinatario.value}-${index}`}
                          value={destinatario.value}
                          onClick={(e) => {
                            e.preventDefault();
                            if (ids.includes(destinatario.value)) {
                              setIds(
                                ids.filter((f) => f != destinatario.value)
                              );
                            } else {
                              setIds((p) => [...p, destinatario.value]);
                            }
                          }}
                        >
                          <Checkbox
                            checked={ids.includes(destinatario.value)}
                          />
                          <ListItemText
                            primary={destinatario.name}
                            secondary={destinatario.details}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={data.programarEnvio ? 6 : 12}>
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={data.programarEnvio}
                              onChange={(e, checked) =>
                                setValue("programarEnvio", checked)
                              }
                            />
                          }
                          label={
                            schemaMensagem.fields.programarEnvio.spec.label
                          }
                        />
                      </FormControl>
                    </Grid>
                    {data.programarEnvio && (
                      <Grid item xs={6}>
                        <DatePicker
                          DialogProps={{ fullWidth: false }}
                          label={schemaMensagem.fields.enviarEm.spec.label}
                          value={data.enviarEm}
                          onChange={(data) => {
                            if (data && !isNaN(data.getTime())) {
                              setValue("enviarEm", data);
                            }
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {mensagemCategoria?.tipo != EMensagemCategoriaTipo.Fotos && (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={data.permiteResposta ? 6 : 12}>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={data.permiteResposta}
                                onChange={(e, checked) => {
                                  if (!checked) {
                                    setValue("notificaResposta", checked);
                                  }
                                  setValue("permiteResposta", checked);
                                }}
                              />
                            }
                            label={
                              schemaMensagem.fields.permiteResposta.spec.label
                            }
                          />
                        </FormControl>
                      </Grid>
                      {data.permiteResposta && (
                        <Grid item xs={6}>
                          <DateTimePicker
                            DialogProps={{ fullWidth: false }}
                            label={schemaMensagem.fields.respostaAte.spec.label}
                            value={data.respostaAte}
                            onChange={(data) => {
                              if (data && !isNaN(data.getTime())) {
                                setValue("respostaAte", data);
                              }
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Grid>
                      )}
                      {data.permiteResposta && (
                        <Grid item xs={12}>
                          <Paper sx={{ p: 1 }} variant="outlined">
                            <ListItem
                              dense
                              disablePadding
                              divider={
                                data.perguntasFormatadas &&
                                data.perguntasFormatadas.length > 0
                              }
                              sx={
                                data.perguntasFormatadas &&
                                data.perguntasFormatadas.length > 0
                                  ? { marginBottom: 1 }
                                  : undefined
                              }
                            >
                              <ListItemIcon>
                                <IconButton
                                  onClick={() => {
                                    if (data.respostaComAnexo) {
                                      snack.warning(
                                        "Respostas formatadas não podem ser usadas com anexos de resposta"
                                      );
                                      return;
                                    }
                                    setPerguntaFormatada(undefined);
                                    showPergunta();
                                  }}
                                >
                                  <AddIcon color="primary" />
                                </IconButton>
                              </ListItemIcon>
                              <ListItemText
                                primary="Adicione uma resposta"
                                secondary="Adicione as opções para respostas formatadas"
                              />
                            </ListItem>
                            {data.perguntasFormatadas &&
                              data.perguntasFormatadas.length > 0 && (
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="center"
                                  spacing={2}
                                >
                                  {data.perguntasFormatadas
                                    ?.filter((f) => !f.deletedAt)
                                    ?.map(
                                      (
                                        pergunta,
                                        index,
                                        perguntasFormatadas
                                      ) => (
                                        <Grid item key={`pergunta-${index}`}>
                                          <Chip
                                            label={pergunta.nome}
                                            variant="outlined"
                                            onClick={() => {
                                              setPerguntaFormatada(pergunta);
                                              showPergunta();
                                            }}
                                            onDelete={() => {
                                              util.handlePerguntaFormatada({
                                                pergunta,
                                                setValue,
                                                hasDelete: true,
                                                perguntasFormatadas,
                                              });
                                            }}
                                          />
                                        </Grid>
                                      )
                                    )}
                                </Grid>
                              )}
                          </Paper>
                        </Grid>
                      )}
                      {data.permiteResposta && (
                        <Grid item xs={12}>
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={data.respostaComAnexo}
                                  onChange={(e, checked) => {
                                    if (
                                      checked &&
                                      (data.perguntasFormatadas ?? [])?.length >
                                        0
                                    ) {
                                      snack.warning(
                                        "Não é possível usar perguntas formatadas com anexos!"
                                      );
                                      checked = false;
                                    }
                                    setValue("respostaComAnexo", checked);
                                  }}
                                />
                              }
                              label={
                                schemaMensagem.fields.respostaComAnexo.spec
                                  .label
                              }
                            />
                          </FormControl>
                        </Grid>
                      )}
                      {data.permiteResposta && (
                        <Grid item xs={12}>
                          <Tooltip title="Marque para receber notificações quando os destinatários responderem">
                            <FormControl>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.notificaResposta}
                                    onChange={(e, checked) => {
                                      setValue("notificaResposta", checked);
                                    }}
                                  />
                                }
                                label={
                                  schemaMensagem.fields.notificaResposta.spec
                                    .label
                                }
                              />
                            </FormControl>
                          </Tooltip>
                        </Grid>
                      )}
                      <Divider />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </PerfectScrollbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={saving}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Form>
  );
};
