import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { blue, red } from "@mui/material/colors";

import { ITheme, LayoutActionsTypes, LayoutState } from "./types";

const INITIAL_STATE: LayoutState = {
  sidebar: {
    isOpen: true,
  },
  rightbar: {
    isOpen: false,
  },
  feedback: {
    isOpen: false,
  },
  theme: {
    mode: "light",
    primary: blue,
    secondary: red,
  },
  devMode: false,
};

export const reducers = createReducer(INITIAL_STATE, {
  [LayoutActionsTypes.SIDEBAR_TOGGLE]: (state) => {
    return {
      ...state,
      sidebar: {
        isOpen: !state.sidebar.isOpen,
      },
    };
  },
  [LayoutActionsTypes.RIGHTBAR_TOGGLE]: (
    state,
    action: PayloadAction<boolean | undefined>
  ) => {
    return {
      ...state,
      rightbar: {
        isOpen:
          action.payload != undefined
            ? action.payload
            : !state.rightbar?.isOpen,
      },
    };
  },
  [LayoutActionsTypes.FEEDBACK_TOGGLE]: (state) => {
    return {
      ...state,
      feedback: {
        isOpen: !state.feedback?.isOpen,
      },
    };
  },
  [LayoutActionsTypes.THEME_TOGGLE]: (
    state,
    action: PayloadAction<ITheme>
  ) => ({
    ...state,
    theme: action.payload,
  }),
  [LayoutActionsTypes.DEVMODE_TOGGLE]: (
    state,
    action: PayloadAction<boolean>
  ) => {
    return {
      ...state,
      devMode: action.payload,
    };
  },
});
