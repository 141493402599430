import React, { FC } from "react";

import { IMensagem } from "@deltasge/marauders-map";
import { history } from "configs";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { Pagination } from "components/Pagination";
import { EmptyState } from "components/EmptyStates";
import { Row } from "./Row";
import { useMobile } from "hooks/useMobile";

export const TabMensagemEnviada: FC<{
  loading: boolean;
  mensagens: [IMensagem[], number];
  handleEdit: (idMensagem?: number | undefined) => void;
  handleDelete: (idMensagem: number) => void;
  handlePublicar: (idMensagem: number, publicar: boolean) => void;
  rowsPerPageOptions: number[];
  rowsPerPage: number;
  page: number;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({
  loading,
  mensagens,
  handleEdit,
  handleDelete,
  handlePublicar,
  rowsPerPageOptions,
  rowsPerPage,
  page,
  onChangeRowsPerPage,
  onChangePage,
}) => {
  const isMd = useMobile("md");
  const isLg = useMobile("lg");

  const handleDetalhes = (idMensagem?: number) =>
    history.push(`/publicacoes/detalhes/${idMensagem}/tab/1`);

  if (!loading && mensagens[0].length === 0) {
    return (
      <EmptyState
        type="404"
        title="Nenhuma publicação enviada!"
        subtitle="O Filtro selecionado não encontrou nenhuma publicação"
      />
    );
  }

  return (
    <Paper>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {!isMd && <TableCell>Categoria</TableCell>}
              <TableCell>Enviado</TableCell>
              <TableCell>Turma</TableCell>
              <TableCell>Matéria</TableCell>
              {!isLg && <TableCell>Enviado Para</TableCell>}
              <TableCell>Entregue</TableCell>
              <TableCell sx={{ width: "180px" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mensagens[0].map((item, i) => (
              <Row
                key={`mensagem-${i}`}
                item={item}
                handleEdit={handleEdit}
                handleDetalhes={handleDetalhes}
                handleDelete={handleDelete}
                handlePublicar={handlePublicar}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        rowsPerPageOptions={rowsPerPageOptions}
        count={mensagens[1]}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </Paper>
  );
};
