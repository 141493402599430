import React, { FC } from "react";

import { IconButton, Tooltip } from "@mui/material";
import { Delete as DeleteIcon, Pencil as EditarIcon } from "mdi-material-ui";

export const BtnDelete: FC<{
  handleClick: () => void;
}> = ({ handleClick }) => {
  return (
    <Tooltip key="btn-delete" title="Excluir mensagem">
      <IconButton color="secondary" aria-label="delete" onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

export const BtnEditar: FC<{
  handleClick: () => void;
}> = ({ handleClick }) => {
  return (
    <Tooltip key="btn-editar" title="Editar mensagem">
      <IconButton color="primary" aria-label="editar" onClick={handleClick}>
        <EditarIcon />
      </IconButton>
    </Tooltip>
  );
};
