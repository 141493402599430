import React, { FC, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import { Send as SendIcon } from "mdi-material-ui";

export const DialoagEnviarNotificacao: FC<{
  handleCancelar: () => void;
  handleEnviar: (params: { title: string; body: string }) => Promise<void>;
  body: string;
  title: string;
}> = ({ handleEnviar, handleCancelar, body: initBody, title: initTitle }) => {
  const [body, setBody] = useState(initBody);
  const [title, setTitle] = useState(initTitle);
  return (
    <>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Título da notificação"
              onChange={({ target: { value } }) => setTitle(value)}
              value={title}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Texto da notificação"
              onChange={({ target: { value } }) => setBody(value)}
              value={body}
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleCancelar}>
          Cancelar
        </Button>
        <Button
          startIcon={<SendIcon />}
          onClick={() => handleEnviar({ title, body })}
        >
          Enviar
        </Button>
      </DialogActions>
    </>
  );
};
