import React, { Dispatch, FC, SetStateAction } from "react";
import {
  IMensagem,
  IMensagemAtribuicaoDestinatario,
} from "@deltasge/marauders-map";

import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import {
  CheckAll as CheckAllIcon,
  Check as CheckIcon,
  Cancel as CancelIcon,
  Send as SendIcon,
} from "mdi-material-ui";

import { Environment } from "configs";
import { IFilter, IParamHandleEscolher } from "../Detalhes";
import { formatDistance, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { LoadingButton } from "@mui/lab";

export const Filter: FC<{
  mensagem: IMensagem;
  filter: IFilter;
  setFilter: Dispatch<SetStateAction<IFilter>>;
  escolherDestinatario: boolean;
  destinatarioEscolhidos: IMensagemAtribuicaoDestinatario[];
  handleEscolherTodos: (params?: IParamHandleEscolher | undefined) => void;
  handleEnviar: () => void;
  enviandoNotificacao: boolean;
  setEscolherDestinatario: Dispatch<React.SetStateAction<boolean>>;
}> = ({
  mensagem,
  filter,
  setFilter,
  handleEscolherTodos,
  setEscolherDestinatario,
  destinatarioEscolhidos,
  handleEnviar,
  enviandoNotificacao,
}) => {
  const showPerguntaFormatadas =
    mensagem.perguntasFormatadas && mensagem.perguntasFormatadas.length > 0;

  const showRespostaAnexos =
    mensagem.permiteResposta && mensagem.respostaComAnexo;

  const showEnviarNotificacao =
    mensagem.permiteResposta &&
    (mensagem.respostaComAnexo ||
      (mensagem.perguntasFormatadas ?? []).length > 0);

  return (
    <Box
      sx={{
        width: ({ spacing }) => spacing(Environment.DRAWER_WIDTH),
        display: "block",
      }}
    >
      <FormControl sx={{ padding: 2 }}>
        <FormLabel id="visualizacao-radio-buttons">Quem visualizou</FormLabel>
        <RadioGroup
          aria-labelledby="visualizacao-radio-buttons"
          name="visualizacao"
          value={
            filter.visualizacao == undefined
              ? "0"
              : filter.visualizacao
              ? "1"
              : "2"
          }
          onChange={({ target: { value } }) => {
            handleEscolherTodos({ limpar: true });
            setFilter({
              ...filter,
              visualizacao:
                value == "0" ? undefined : value == "1" ? true : false,
            });
          }}
        >
          <FormControlLabel
            value="0"
            control={<Radio />}
            label={<b>TODOS</b>}
          />
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="Somente os visualizados"
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label="Somente quem não visualizou"
          />
        </RadioGroup>
      </FormControl>
      <FormControl sx={{ padding: 2 }}>
        <FormLabel id="curtiu-radio-buttons">Quem curtiu</FormLabel>
        <RadioGroup
          aria-labelledby="curtiu-radio-buttons"
          name="curtiu"
          value={filter.custida == undefined ? "0" : filter.custida ? "1" : "2"}
          onChange={({ target: { value } }) => {
            handleEscolherTodos({ limpar: true });
            setFilter({
              ...filter,
              custida: value == "0" ? undefined : value == "1" ? true : false,
            });
          }}
        >
          <FormControlLabel
            value="0"
            control={<Radio />}
            label={<b>TODOS</b>}
          />
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="Somente quem curtiu"
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label="Somente quem não curtiu"
          />
        </RadioGroup>
      </FormControl>
      {showPerguntaFormatadas && (
        <FormControl sx={{ padding: 2 }}>
          <FormLabel id="perguntas-radio-buttons">Quem respondeu</FormLabel>
          <RadioGroup
            aria-labelledby="perguntas-radio-buttons"
            name="perguntas"
            value={filter.pergunta}
            onChange={({ target: { value } }) => {
              handleEscolherTodos({ limpar: true });
              setFilter({
                ...filter,
                pergunta: parseInt(value, 10),
              });
            }}
          >
            <FormControlLabel
              value={0}
              control={<Radio />}
              label={<b>TODAS</b>}
            />
            <FormControlLabel
              value={-1}
              control={<Radio />}
              label={<b>Não respondidas</b>}
            />
            {(mensagem.perguntasFormatadas ?? []).map((m) => (
              <FormControlLabel
                key={`pergunta-${m.id}`}
                value={m.id}
                control={<Radio />}
                label={m.nome}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
      {showRespostaAnexos && (
        <FormControl sx={{ padding: 2 }}>
          <FormLabel id="perguntas-radio-buttons">Quem entregou</FormLabel>
          <RadioGroup
            aria-labelledby="perguntas-radio-buttons"
            name="devolvido"
            value={
              filter.devolvido == undefined
                ? "todos"
                : filter.devolvido == true
                ? "sim"
                : "nao"
            }
            onChange={({ target: { value } }) => {
              handleEscolherTodos({ limpar: true });
              setFilter({
                ...filter,
                devolvido:
                  value == "sim" ? true : value == "nao" ? false : undefined,
              });
            }}
          >
            <FormControlLabel
              value={"todos"}
              control={<Radio />}
              label={<b>TODOS</b>}
            />
            <FormControlLabel
              value={"nao"}
              control={<Radio />}
              label="Entregue"
            />
            <FormControlLabel
              value={"sim"}
              control={<Radio />}
              label="Devolvido"
            />
          </RadioGroup>
        </FormControl>
      )}
      {showEnviarNotificacao && (
        <FormControl sx={{ padding: 2 }}>
          <FormLabel>Reenviar notificação</FormLabel>
          {mensagem.respostaAte && (
            <Typography variant="caption">
              Esta publicação tem um prazo de&nbsp;
              <b>
                {formatDistance(
                  parseISO(mensagem.respostaAte.toString()),
                  new Date(),
                  { locale: ptBR }
                )}
              </b>
              &nbsp; dias para entrega.
            </Typography>
          )}
          <Typography variant="caption">
            {`Envie uma notificação para quem ainda não ${
              mensagem.respostaComAnexo ? "entregou" : "respondeu"
            }`}
          </Typography>
          <ButtonGroup size="small" fullWidth variant="outlined">
            {destinatarioEscolhidos.length > 0 && (
              <Button
                startIcon={<CancelIcon />}
                onClick={() => handleEscolherTodos({ limpar: true })}
                color="secondary"
              >
                Limpar
              </Button>
            )}
            {destinatarioEscolhidos.length == 0 && (
              <Button
                onClick={() => handleEscolherTodos()}
                startIcon={<CheckAllIcon />}
              >
                Todos
              </Button>
            )}
            {destinatarioEscolhidos.length == 0 && (
              <Button
                onClick={() => setEscolherDestinatario(true)}
                startIcon={<CheckIcon />}
              >
                Escolher
              </Button>
            )}
            {destinatarioEscolhidos.length > 0 && (
              <LoadingButton
                disabled={enviandoNotificacao}
                variant="outlined"
                loading={enviandoNotificacao}
                startIcon={<SendIcon />}
                onClick={() => handleEnviar()}
              >
                Enviar
              </LoadingButton>
            )}
          </ButtonGroup>
        </FormControl>
      )}
    </Box>
  );
};
