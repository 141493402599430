import React, { FC, useState } from "react";
import { format, parseISO } from "date-fns";
import { IAvaliacao } from "@deltasge/marauders-map";

import {
  Checkbox,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableRow as TableRowMUI,
  Tooltip,
} from "@mui/material";
import {
  DotsVertical as MoreIcon,
  Pencil as EditIcon,
  Delete as DeleteIcon,
} from "mdi-material-ui";

import { useAppSelector } from "store";
import { useMobile } from "hooks/useMobile";

export const TableRowComponent: FC<{
  avaliacao: IAvaliacao;
  selecionadas: number[];
  disabled?: boolean;
  editing: boolean;
  handleEdit: (idAvaliacao: number) => Promise<void>;
  handleDelete: (payload: number) => void;
  handleSelect: (idAvaliacao: number) => void;
}> = ({
  avaliacao,
  disabled = false,
  editing,
  handleEdit,
  handleDelete,
  handleSelect,
  selecionadas,
}) => {
  const {
    calculoAvaliacoes: { data: calculos },
  } = useAppSelector((state) => state);
  const calculo = calculos.find((f) => f.calculo == avaliacao.calculo);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isSm = useMobile("sm");
  const isMd = useMobile("md");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  return (
    <TableRowMUI
      hover
      onClick={() => handleSelect(avaliacao.uid ?? 0)}
      role="checkbox"
      aria-checked={selecionadas.includes(avaliacao.uid ?? 0)}
      tabIndex={-1}
      selected={selecionadas.includes(avaliacao.uid ?? 0)}
      sx={{ cursor: "pointer" }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={selecionadas.includes(avaliacao.uid ?? 0)}
          inputProps={{
            "aria-labelledby": `label-${avaliacao.uid}`,
          }}
        />
      </TableCell>
      <TableCell>{avaliacao.descricao}</TableCell>
      {!isSm && (
        <TableCell align="right">
          {format(parseISO(avaliacao.data), "dd/MM/yyyy")}
        </TableCell>
      )}
      <TableCell align="right">{avaliacao.codigo}</TableCell>
      <TableCell align="right">{avaliacao.valor}</TableCell>
      {!isSm && (
        <TableCell>{calculo?.descricao ?? avaliacao.calculo}</TableCell>
      )}
      {!isSm && !isMd && (
        <TableCell>
          {`${
            avaliacao.professor
              ? "Professor: ".concat(avaliacao.professor.nome.rUpper())
              : avaliacao.usuario
              ? "Usuário: ".concat(avaliacao.usuario.nome.rUpper())
              : "Secretaria"
          }`}
        </TableCell>
      )}
      <TableCell align="right">
        {!isSm && !isMd && (
          <>
            <Tooltip title="Editar">
              <>
                {editing ? (
                  <CircularProgress sx={{ p: 1 }} />
                ) : (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEdit(avaliacao.uid ?? 0);
                    }}
                    disabled={disabled}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </>
            </Tooltip>
            <Tooltip title="Remover">
              <>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(avaliacao.uid ?? 0);
                  }}
                  disabled={disabled}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            </Tooltip>
          </>
        )}
        {(isSm || isMd) && (
          <>
            <IconButton onClick={handleClick}>
              <MoreIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                disabled={disabled}
                onClick={() => {
                  handleClose();
                  handleEdit(avaliacao.uid ?? 0);
                }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Editar</ListItemText>
              </MenuItem>
              <MenuItem
                disabled={disabled}
                onClick={() => {
                  handleClose();
                  handleDelete(avaliacao.uid ?? 0);
                }}
              >
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Excluir</ListItemText>
              </MenuItem>
            </Menu>
          </>
        )}
      </TableCell>
    </TableRowMUI>
  );
};
